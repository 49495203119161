"use client"

import * as React from "react"
import {Check, ChevronsUpDown} from "lucide-react"

import {cn} from "@/lib/utils"
import {Button} from "@/components/ui/button"
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList,} from "@/components/ui/command"
import {Popover, PopoverContent, PopoverTrigger,} from "@/components/ui/popover"
import _ from "lodash";

export interface ComboboxProps {
    placeholder?: string
    onChangeQuery: (value: string) => void,
    items: {value: string, label: string}[]
    onChangeValue: (value: string) => void,
    value?: {value: string, label: string}
}

const Combobox: React.FC<ComboboxProps> = (props) => {
    const {placeholder, onChangeQuery, items, value, onChangeValue} = props
    const [open, setOpen] = React.useState(false)

    const _onChangeQuery = _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeQuery(event.target.value);
    }, 300);

    const _onChangeValue = (currentValue: string) => {
        onChangeValue(currentValue)
        setOpen(false)
    }

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-[200px] justify-between"
                >
                    {value ? value.label : placeholder}
                    <ChevronsUpDown className="opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
                <Command shouldFilter={false}>
                    <CommandInput placeholder="Search framework..." onChangeCapture={_onChangeQuery} />
                    <CommandList>
                        <CommandEmpty>No framework found.</CommandEmpty>
                        <CommandGroup>
                            {items.map((item) => (
                                <CommandItem
                                    key={item.value}
                                    value={item.value}
                                    onSelect={_onChangeValue}
                                >
                                    {item.label}
                                    <Check
                                        className={cn(
                                            "ml-auto",
                                            value?.value === item.value ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

export default Combobox