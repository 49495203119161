import * as React from "react"
import {Card, CardContent,} from "@/components/ui/card"
import {Input} from "@/components/ui/input"
import {Label} from "@/components/ui/label"
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue,} from "@/components/ui/select"
import {LLMOperator,} from "@/model/Workflow";
import {GPTPrompt, GPTPromptRoleType, LLMVendorType} from "@/model/llmSpecification";
import {Textarea} from "@/components/ui/textarea";
import {CirclePlus} from "lucide-react";
import {OperatorDetailProps} from "@/view/workflow/components/operatorForm/index";

interface LLMOperatorFormProps extends OperatorDetailProps {
    operator: LLMOperator
}


export const LLMOperatorForm: React.FC<LLMOperatorFormProps> = ({operator, readOnly = false}) => {
    return (
        <div className="grid w-full items-center gap-4 h-full">
            <Card className='p-6'>
                <CardContent className="grid gap-4">
                    <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="name">Name</Label>
                        <Input id="name" placeholder="Name of your project" defaultValue={operator.name} readOnly={readOnly}/>
                    </div>
                    <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="framework">LLM Providers</Label>
                        <Select defaultValue={operator.model.vendor} disabled={readOnly}>
                            <SelectTrigger id="framework">
                                <SelectValue placeholder="Select"/>
                            </SelectTrigger>
                            <SelectContent position="popper">
                                {Object.values(LLMVendorType).map((vendor) => (
                                    <SelectItem value={vendor}>{vendor}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </CardContent>
            </Card>

            <div className="flex flex-col space-y-1.5">
                <Label htmlFor="framework">Prompts</Label>

                <div className='flex flex-col gap-2 mt-3'>
                    {operator.prompts.map((prompt) => (
                        <PromptForm prompt={prompt} readonly={readOnly}/>
                    ))}
                    {!readOnly &&
                        <div className='flex justify-center'>
                            <CirclePlus role={'button'}/>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

const PromptForm: React.FC<{ prompt: GPTPrompt, readonly: boolean}> = ({prompt, readonly}) => {
    const onChangeContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        prompt.content = e.target.value
    }

    const onChangeRole = (role: GPTPromptRoleType) => {
        prompt.role = role
    }
    return (
        <Card className='p-6'>
            <CardContent>
                <form>
                    <div className="grid w-full items-center gap-4">
                        <div className="flex flex-col space-y-1.5">
                            <Label htmlFor="framework">Role</Label>
                            <Select defaultValue={prompt.role} onValueChange={onChangeRole} disabled={readonly}>
                                <SelectTrigger id="framework">
                                    <SelectValue placeholder="Select"/>
                                </SelectTrigger>
                                <SelectContent position="popper">
                                    {Object.values(GPTPromptRoleType).map((type) => (
                                        <SelectItem value={type}>{type}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="flex flex-col space-y-1.5">
                            <Label htmlFor="name">Name</Label>
                            <Textarea placeholder="Type your message here."
                                      rows={15}
                                      disabled={readonly}
                                      defaultValue={prompt.content}
                                      onChange={onChangeContent}/>
                        </div>
                    </div>
                </form>
            </CardContent>

        </Card>
    )
}

export default LLMOperatorForm
