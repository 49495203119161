import React, {useCallback, useRef, useState} from "react";
import chatbotRepository from "@/respsitory/ChatbotRepository";
import {Chatbot} from "@/model/Chatbot";
import toast from "react-hot-toast";
import {Input} from "@/components/ui/input";
import {Card, CardContent, CardFooter, CardHeader} from "@/components/ui/card";
import {cn} from "@/lib/utils";
import {Button} from "@/components/ui/button";
import {Send} from "lucide-react";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger} from "@/components/ui/sheet";
import moment from "moment/moment";
import {formatIsoReadable} from "@/utils/dateUtils";
import useWorkflowLog from "@/query/workflowLog/useWorkflowLog";
import {statusBadge} from "@/view/workflowLog/workflowLogList";
import {isAxiosError} from "@/error";
import WorkflowLogViewer from "@/view/workflowLog/component/WorkflowLogViewer";

interface QueryMessage {
    _id: string
    role: "user"
    content: string
}

interface AgentMessage {
    _id: string
    role: 'agent'
    content: string
    resultId: string
}

const ChatSandbox: React.FC<{ chatbot: Chatbot }> = ({chatbot}) => {
    const queryIndex = useRef(0)
    const queryRef = useRef<HTMLInputElement>(null);
    const sessionIdRef = useRef<string | undefined>(undefined);

    const [pending, setPending] = useState(false);
    const [messages, setMessages] = useState<(QueryMessage | AgentMessage)[]>([]);

    const onKeyUpEnter = useCallback(async (e: any) => {
        e.preventDefault()
        if (pending || !queryRef.current || queryRef.current.value.length < 1 || !queryRef.current.value) return

        const query = queryRef.current.value
        queryIndex.current += 1
        try {
            setPending(true)
            setMessages((prev) => [{_id: queryIndex.current.toString(), role: "user", content: query},  ...(prev)])
            queryRef.current.value = ''

            const {data} = await chatbotRepository.chat(chatbot._id, query, sessionIdRef.current)
            sessionIdRef.current = data.result.sessionId
            setMessages((prev) => [{_id: data.result._id, role: "agent", content: data.result.outlet.answer, resultId: data.result._id},  ...(prev)])

        } catch (e) {

            if (isAxiosError(e) && e.response) {
                const data = e.response.data as any
                setMessages((prev) => [{_id: data.result._id, role: "agent", content: data.result.outlet.answer, resultId: data.result._id},  ...(prev)])
            } else {
                console.log(e)
                toast.error("에러")
            }

        } finally {
            setPending(false)

        }

    }, [chatbot._id, pending])

    // console.log(messages)
    return (
        <Card className='h-full flex flex-col'>
            <CardHeader className="flex flex-row items-center">
                <div className="flex items-center space-x-4">
                    {/*<Avatar>*/}
                    {/*    <AvatarImage src="/avatars/01.png" alt="Image" />*/}
                    {/*    <AvatarFallback>OM</AvatarFallback>*/}
                    {/*</Avatar>*/}
                    <div>
                        <p className="text-sm font-medium leading-none">Chatbot '{chatbot.name}'</p>
                        {/*<p className="text-sm text-muted-foreground">m@example.com</p>*/}
                    </div>
                </div>
                {/*<TooltipProvider delayDuration={0}>*/}
                {/*    <Tooltip>*/}
                {/*        <TooltipTrigger asChild>*/}
                {/*            <Button*/}
                {/*                size="icon"*/}
                {/*                variant="outline"*/}
                {/*                className="ml-auto rounded-full"*/}
                {/*                onClick={() => setOpen(true)}*/}
                {/*            >*/}
                {/*                <Plus />*/}
                {/*                <span className="sr-only">New message</span>*/}
                {/*            </Button>*/}
                {/*        </TooltipTrigger>*/}
                {/*        <TooltipContent sideOffset={10}>New message</TooltipContent>*/}
                {/*    </Tooltip>*/}
                {/*</TooltipProvider>*/}
            </CardHeader>
            <CardContent className='grow overflow-y-auto flex-col-reverse flex'>
                {messages.map((message) => {
                    if (message.role === 'user') {
                        return (
                            <div
                                key={`query-${message._id}`}
                                className={cn(
                                    "flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm mb-2",
                                    "ml-auto bg-primary text-primary-foreground"
                                )}
                            >
                                {message.content}
                            </div>
                        )
                    } else {
                        return (
                            <Sheet key={`agent-${message._id}`}>
                                <SheetTrigger asChild role={'button'}>
                                    <div
                                        className={cn(
                                            "flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm mb-2", "bg-muted"
                                        )}
                                    >
                                        {message.content}
                                    </div>
                                </SheetTrigger>
                                <LogSheetContent workflowResultId={message.resultId}/>
                            </Sheet>
                        )
                    }
                })}
            </CardContent>
            <CardFooter>
                <form
                    onSubmit={onKeyUpEnter}
                    className="flex w-full items-center space-x-2"
                >
                    <Input
                        id="message"
                        placeholder="Type your message..."
                        className="flex-1"
                        autoComplete="off"
                        ref={queryRef}
                        autoFocus={true}
                    />
                    <Button type="submit" size="icon" disabled={pending}>
                        <Send/>
                        <span className="sr-only">Send</span>
                    </Button>
                </form>
            </CardFooter>
        </Card>
    )
}

const LogSheetContent: React.FC<{ workflowResultId: string }> = ({workflowResultId}) => {

    const {data: log} = useWorkflowLog(workflowResultId)
    const layout = localStorage.getItem("react-resizable-panels:layout:workflow-log")

    const defaultLayout = layout ? JSON.parse(layout) : undefined

    if (!log) return null
    return (
        <SheetContent
            className="w-[90%] sm:w-[90%] sm:max-w-[90%] h-screen flex flex-col"
        >
            <SheetHeader className='flex'>
                <SheetTitle className='flex items-center'>

                    <span className='mr-2'>{log.workflowName}</span>

                    {statusBadge(log)}

                </SheetTitle>
                <SheetDescription>
                    <span
                        className='text-xs text-slate-500 mr-5'>Duration (ms): {moment.utc(log.createdAt).diff(moment.utc(log.startedAt), 'milliseconds').toLocaleString("ko-KR")}</span>
                    <span className='text-xs text-slate-500 mr-5'>Started At: {formatIsoReadable(log.startedAt)}</span>
                    <span className='text-xs text-slate-500 mr-5'>Ended At: {formatIsoReadable(log.createdAt)}</span>

                </SheetDescription>
            </SheetHeader>

            <div className='rounded-xl border bg-card text-card-foreground shadow w-full h-full overflow-hidden'>
                <WorkflowLogViewer
                    className='grow'
                    log={log}
                    defaultLayout={defaultLayout}
                />
            </div>

            {/*<SheetFooter>*/}
            {/*    {job.status === 'running' &&*/}
            {/*        <Button type="submit" variant='destructive' onClick={interrupt}>*/}
            {/*            Interrupt*/}
            {/*        </Button>*/}
            {/*    }*/}
            {/*    <Button type="submit" variant='destructive' onClick={interrupt}>*/}
            {/*        Interrupt*/}
            {/*    </Button>*/}
            {/*    /!*<SheetClose asChild>*!/*/}
            {/*    /!*    <Button type="submit">Save changes</Button>*!/*/}
            {/*    /!*</SheetClose>*!/*/}
            {/*</SheetFooter>*/}
        </SheetContent>
    )
}

export default ChatSandbox