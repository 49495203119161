import ChatSandbox from "@/view/chatbot/components/ChatSandbox";
import {useParams} from "react-router-dom";
import useChatbot from "@/query/chatbot/useChatbot";

const ChatSandboxView = () => {
    const { chatbotId } = useParams<{ chatbotId: string}>();

    const {data: chatbot} = useChatbot(chatbotId!);

    if (!chatbot) return null

    return (
        <div className='h-full p-2'>
            <ChatSandbox chatbot={chatbot}/>
        </div>
    )
}

export default ChatSandboxView