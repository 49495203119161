import ErrorBoundaryLayout from "./component/error/ErrorBoundaryLayout";
import NewDashboardView from "./view/dashboard/NewDashboardView";
import ChannelDetailTab from "./view/channel/setting/ChannelDetailTab";
import PaymentsHistoryView from "./view/payments/PaymentsHistoryView";
import UserView from "./view/user/UserView";
import UserDetail from "./view/user/UserDetail";
import SettingTab from "./view/setting/SettingTab";
import React, {Suspense} from "react";
import {Navigate, RouteObject} from "react-router-dom";
import {WorkflowLogMain} from "@/view/workflowLog/workflowLogMain";
import WorkflowMain from "@/view/workflow/workflowMain";
import {Skeleton} from "@/components/ui/skeleton";
import WorkspaceWrapper from "@/view/channel/setting/WorkspaceWrapper";
import WorkflowDetailWrapper from "@/view/workflow/WorkflowDetailWrapper";
import WorkflowGraphView from "@/view/workflow/WorkflowGraphView";
import WorkflowRunView from "@/view/workflow/WorkflowRunView";
import WorkflowJobListView from "@/view/workflow/WorkflowJobListView";
import WorkflowMetaView from "@/view/workflow/WorkflowMetaView";
import ChatbotListView from "@/view/chatbot/ChagtbotListView";
import ChatbotDetailView from "@/view/chatbot/ChatbotDetailView";
import ChatSandboxView from "@/view/chatbot/ChatSandboxView";
import ChannelMainView from "@/view/channel/ChannelMainView";
import WorkflowJobView from "@/view/workflowJob/WorkflowJobView";

export type Route  = RouteObject & {
    title?: string
}

const routes = [
    {
        path: "",
        element: <ErrorBoundaryLayout/>,
        children: [
            {title: "Dashboard", path: 'dashboard', element: <NewDashboardView/>},
            {
                title: "Workspace",
                path: "channels",
                children: [
                    {
                        index: true,
                        element: <ChannelMainView/>
                    },
                    {
                        title: "title",
                        path: ":channelId",
                        element: <WorkspaceWrapper/>,
                        children: [
                            {
                                index: true,
                                element: <ChannelDetailTab/>
                            },
                        ]
                    },
                ]
            },
            {
                title: "Chatbot",
                path: "chatbots",
                children: [
                    {
                        index: true,
                        element: <ChatbotListView/>
                    },
                    {
                        path: ':chatbotId',
                        element: (
                            <Suspense fallback={<Skeleton className="h-[125px] w-full rounded-xl"/>}>
                                <ChatbotDetailView/>
                            </Suspense>
                        )
                    },
                    {
                        path: ':chatbotId/chat',
                        element: (
                            <Suspense fallback={<Skeleton className="h-[125px] w-full rounded-xl"/>}>
                                <ChatSandboxView/>
                            </Suspense>
                        )
                    }
                ]
            },
            {
                title: "Payment",
                path: "payments",
                element: <PaymentsHistoryView/>
            },
            {
                title: "User",
                path: "user",
                children: [
                    {
                        index: true,
                        element: <UserView/>
                    },
                    {
                        path: ":userId",
                        element: <UserDetail/>
                    }
                ]
            },
            {
                title: "Setting",
                path: "setting",
                element: <SettingTab/>,
            },
            {
                title: "Workflow",
                path: "workflow-service",
                children: [
                    {
                        title: "Workflow",
                        path: "workflows",
                        children: [
                            {
                                index: true,
                                element: <WorkflowMain/>
                            },
                            {
                                path: ":workflowId",
                                element: <WorkflowDetailWrapper/>,
                                children: [
                                    {
                                        path: "graph",
                                        index: true,
                                        element: <WorkflowGraphView/>,
                                    },
                                    {
                                        path: "meta",
                                        element: <WorkflowMetaView/>
                                    },
                                    {
                                        path: "run",
                                        element: <WorkflowRunView/>
                                    },
                                    {
                                        index: true,
                                        element: <Navigate to='graph'/>,
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        title: "Workflow Job",
                        path: "jobs",
                        children: [
                            {
                                index: true,
                                element: <WorkflowJobListView/>,
                            },
                            {
                                path: ':jobId',
                                element: (
                                    <Suspense fallback={<Skeleton className="h-[125px] w-full rounded-xl"/>}>
                                        <WorkflowJobView/>
                                    </Suspense>
                                )
                            }
                        ]
                    },
                    {
                        title: "Workflow Result",
                        path: "results",
                        element: <WorkflowLogMain/>,
                    }
                ],
            },
        ],
    },
]
export default routes