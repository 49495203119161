import {Button} from "@/components/ui/button"
import {Workflow} from "@/model/Workflow";
import useModal, {Modal} from "@/hook/useModal";
import useUpdateWorkflow from "@/query/workflow/useUpdateWorkflow";
import toast from "react-hot-toast";

export const SAVE_WORKFLOW_MODAL_ID = "save-workflow";


interface WorkflowSaveDialogProps {
    workflow: Workflow
}

const WorkflowSaveDialog: React.FC<WorkflowSaveDialogProps> = ({workflow}) => {

    const modal= useModal()
    const {mutateAsync: updateWorkflow, isLoading} = useUpdateWorkflow(workflow._id)

    const onSubmit = async () => {
        if (isLoading) return

        const loadingToastId = toast.loading("logging in")
        try {
            await updateWorkflow(workflow);
            toast.success("Success", {id: loadingToastId})
        } catch (e) {
            console.log(e)
            toast.error("error", {id: loadingToastId});
        } finally {
            close()
        }
    }
    const close = () => modal.close(SAVE_WORKFLOW_MODAL_ID)

    return (
        <Modal id={SAVE_WORKFLOW_MODAL_ID}
               size="sm"
               title={'Deploy Workflow'}
        >
            <div className='pt-3'>
                <span>{workflow.name}</span>
            </div>
                <div className="flex justify-end space-x-2">
                    <Button variant="outline"
                            onClick={close}
                    >
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} disabled={isLoading}>
                        Deploy
                    </Button>
                </div>
        </Modal>
    )
}

export default WorkflowSaveDialog
