import React, {useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useChatbot from "@/query/chatbot/useChatbot";
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "@/components/ui/card";
import {Label} from "@/components/ui/label";
import {Button} from "@/components/ui/button";
import toast from "react-hot-toast";
import useUpdateChatbot from "@/query/chatbot/useUpdateChatbot";
import {WorkflowChatHandlerComboBoxRef, WorkflowComboBox} from "@/view/chatbot/ChatHandlerCombobox";
import {Chatbot, WorkflowHandler} from "@/model/Chatbot";

const ChatbotDetailView = () => {
    const {chatbotId} = useParams() as { chatbotId: string };

    const {data: chatbot} = useChatbot(chatbotId)

    if (!chatbot) return null

    return <Content chatbot={chatbot}/>

}

const Content: React.FC<{ chatbot: Chatbot }> = ({chatbot}) => {

    const navigate = useNavigate();

    const chatHandlerRef = useRef<WorkflowChatHandlerComboBoxRef>(null)
    const welcomeHandlerRef = useRef<WorkflowChatHandlerComboBoxRef>(null)

    // const [chatHandler, setChatHandler] = useState<WorkflowHandler | undefined>(chatbot.chatHandler)
    // const [welcomeHandler, setWelcomeHandler] = useState<WorkflowHandler | undefined>(chatbot.welcomeHandler)

    const {mutateAsync: update, isLoading} = useUpdateChatbot(chatbot._id)

    const onSubmit = async () => {
        if (!chatHandlerRef.current || !welcomeHandlerRef.current || isLoading) return

        const body: {chatHandler?: WorkflowHandler, welcomeHandler?: WorkflowHandler} = {}

        const chatWorkflow = chatHandlerRef.current.chatHandler()
        const welcomeWorkflow = welcomeHandlerRef.current.chatHandler()
        if (!chatWorkflow) {
            toast.error("Chat Handler 설정이 필요합니다.")
            return
        }

        if (!welcomeWorkflow) {
            toast.error("Welcome Handler 설정이 필요합니다.")
            return
        }

        if (chatbot.chatHandler) {
            body.chatHandler = {...chatbot.chatHandler, workflowId: chatWorkflow._id}
        }

        if (chatbot.welcomeHandler) {
            body.welcomeHandler = {...chatbot.welcomeHandler, workflowId: welcomeWorkflow._id}
        }


        try {
            await update(body)
            toast.success("저장")
        } catch (e) {
            toast.error("실패")
        }
    }

    return (
        <div className='p-2'>
            <div className='flex justify-end items-center mb-2'>
                <Button variant="outline" onClick={() => navigate(`/chatbots/${chatbot._id}/chat`)}>Go Chat</Button>
            </div>
            <Card>
                <CardHeader>
                    <CardTitle></CardTitle>
                    {/*<CardDescription>...</CardDescription>*/}
                </CardHeader>
                <CardContent className='grid gap-4'>
                    <div className="w-full items-center gap-4 flex ">


                        <div className='flex gap-3 items-center'>
                            <div className='flex gap-3 items-center'>
                                <Label htmlFor="framework">Chat Handler </Label>
                                <WorkflowComboBox defaultWorkflowId={chatbot.chatHandler?.workflowId}
                                                  ref={chatHandlerRef} labels={['sitebunny-chat']}/>
                            </div>
                        </div>
                    </div>

                    <div className="w-full items-center gap-4 flex ">

                        <div className='flex gap-3 items-center'>
                            <Label htmlFor="framework">Welcome Handler </Label>
                            <WorkflowComboBox defaultWorkflowId={chatbot.welcomeHandler?.workflowId}
                                              ref={welcomeHandlerRef}
                                              labels={['sitebunny-welcome']}/>
                        </div>
                    </div>

                </CardContent>
                <CardFooter className="flex justify-end">
                    <Button disabled={isLoading} onClick={onSubmit}>저장</Button>
                </CardFooter>
            </Card>
        </div>
    )
}


export default ChatbotDetailView