import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import workflowRepository from "../../respsitory/workflowRepository";
import {Workflow} from "@/model/Workflow";


const useUpdateWorkflow = (workflowId: string) => {
    const queryClient = useQueryClient();
    return useMutation<undefined, AxiosError, Workflow>({
        mutationKey: ["workflow", 'update', workflowId],
        mutationFn: async (workflow: Workflow) => {
            try {
                const {data} = await workflowRepository.update(workflow);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["workflow", workflowId],
                exact: false,
            });
        }
    })
};
export default useUpdateWorkflow