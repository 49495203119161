import React, {useMemo} from "react";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useWorkflow from "@/query/workflow/useWorkflow";
import '@xyflow/react/dist/style.css';
import WorkflowSaveDialog from "@/view/workflow/components/WorkflowSaveDialog";
import {cn} from "@/lib/utils";
import WorkflowCloneDialog, {CLONE_WORKFLOW_MODAL_ID} from "@/view/workflow/components/WorkflowCloneDialog";
import useModal from "@/hook/useModal";

const PATH_ITEMS = ['graph', 'run', 'meta']

export const WorkflowDetailWrapper = () => {

    const {workflowId} = useParams<{ channelId: string, workflowId: string }>();
    const {data: workflow} = useWorkflow(workflowId!)
    const location = useLocation();
    const modal = useModal()

    const activeTab = useMemo(() => {

        for (let pathItem of PATH_ITEMS) {
            if (location.pathname.endsWith(pathItem)) {
                return pathItem
            }
        }
    } ,[location.pathname])
    if (!workflow) return null

    return (
        <div className='h-full flex flex-col'>
            <div className="border-b grow-0 shrink-0">
                <div className="flex items-center px-4 py-3">
                    <nav
                        className={cn("flex items-center space-x-4 lg:space-x-6", 'mx-6')}
                    >
                        <Link
                            to={`/workflow-service/workflows/${workflowId}/graph`}
                            className={cn("text-sm font-medium transition-colors", activeTab === 'graph' ? ' hover:text-primary' : 'text-muted-foreground transition-colors hover:text-primary')}
                        >
                            Graph
                        </Link>
                        <Link
                            to={`/workflow-service/workflows/${workflowId}/meta`}
                            className={cn("text-sm font-medium transition-colors", activeTab === 'meta' ? ' hover:text-primary' : 'text-muted-foreground transition-colors hover:text-primary')}

                        >
                            Meta
                        </Link>
                        <Link
                            to={`/workflow-service/workflows/${workflowId}/run`}
                            className={cn("text-sm font-medium transition-colors", activeTab === 'run' ? ' hover:text-primary' : 'text-muted-foreground transition-colors hover:text-primary')}

                        >
                            Run
                        </Link>
                        <button
                            className={cn("text-sm font-medium transition-colors", 'text-muted-foreground transition-colors hover:text-primary')}
                            onClick={() => {
                                modal.open(CLONE_WORKFLOW_MODAL_ID)
                            }}

                        >
                            Clone
                        </button>
                    </nav>
                </div>
            </div>
            <div className='grow overflow-hidden'>
                <Outlet/>
            </div>

            <WorkflowSaveDialog workflow={workflow}/>
            <WorkflowCloneDialog workflow={workflow}/>
        </div>

    )
}

export default WorkflowDetailWrapper;