import {useMutation} from "@tanstack/react-query";
import {AxiosError} from "axios";
import adminSettingRepository from "../../respsitory/AdminSettingRepository";


const useCreateWorkflow = (defaultChatWorkflowId: string) => {
    return useMutation<undefined, AxiosError>({
        mutationKey: ["workflows", 'create'],
        mutationFn: async () => {
            try {
                const {data} = await adminSettingRepository.create(defaultChatWorkflowId);
                return data.result;
            } catch (e) {
                throw e;
            }
        }
    })
};

export default useCreateWorkflow