import React, {useMemo} from "react";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {SiteBunnyAdminError} from "@/error";
import useProfile from "../query/auth/useProfile";
import {AppSidebar} from "@/components/app-sidebar";
import {SidebarInset, SidebarProvider, SidebarTrigger} from "@/components/ui/sidebar";
import {Separator} from "@/components/ui/separator";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator
} from "@/components/ui/breadcrumb";
import routes, {Route} from '../route'

type AuthProviderProps = {
    children?: React.ReactNode;
}

const Authenticator: React.FC<AuthProviderProps> = () => {
    const {isLoading, isSuccess, error} = useProfile({useErrorBoundary: false});

    const location = useLocation();
    const navigate = useNavigate()
    const pathItem: { title: string, path: string }[] = useMemo(() => {
        let route: Route[] = [...routes]
        let matchedRoute: undefined | Route = undefined
        let loop = 0
        let result = []
        const pathByDepth = location.pathname.split('/')
        while (route && pathByDepth && loop < 3) {
            loop += 1
            matchedRoute = route.find((v) => v.path === pathByDepth[0])

            if (matchedRoute) {
                pathByDepth.shift()

                if (matchedRoute.title && matchedRoute.path) {
                    result.push({title: matchedRoute.title, path: matchedRoute.path})
                }
                if (matchedRoute.children) {
                    route = matchedRoute.children
                } else {
                    route = []
                }
            } else if (route) {
                break
            }
        }
        return result
    }, [location.pathname])

    if (isLoading) return <></>;

    if (isSuccess) {
        return (
            <SidebarProvider>
                <AppSidebar/>
                <SidebarInset className='overflow-hidden'>
                    <header className="flex basis-16 shrink-0 items-center gap-2 border-b px-3">
                        <SidebarTrigger/>
                        <Separator orientation="vertical" className="mr-2 h-4"/>
                        {pathItem &&
                            <Breadcrumb>
                                <BreadcrumbList>
                                    <BreadcrumbItem className="hidden md:block"
                                                    onClick={() => navigate(pathItem[0].path)}>
                                        <BreadcrumbLink href="#">
                                            {pathItem[0].title}
                                        </BreadcrumbLink>
                                    </BreadcrumbItem>
                                    {pathItem.slice(1).map((item) => (
                                        <React.Fragment key={`path-${item.path}`}>
                                            <BreadcrumbSeparator className="hidden md:block"/>
                                            <BreadcrumbItem onClick={() => navigate(item.path)}>
                                                <BreadcrumbPage>{item.title}</BreadcrumbPage>
                                            </BreadcrumbItem>
                                        </React.Fragment>
                                    ))}

                                </BreadcrumbList>
                            </Breadcrumb>
                        }

                    </header>
                    <div className="flex grow flex-col gap-4 overflow-auto">
                        <Outlet/>
                    </div>
                </SidebarInset>
            </SidebarProvider>
        )
    } else {
        if (error?.code === "401") {
            return <Navigate to={'/login'}/>;
        } else {
            throw new SiteBunnyAdminError(error?.code, "An error occurred during auth.")
        }
    }
};

export default Authenticator;
