import logRepository from "../../respsitory/workflowLogRepository";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {WorkflowLog} from "@/model/Log";


const getData = async (resultId: string) => {
    try {
        const {data} = await logRepository.read(resultId)
        return data.result
    } catch (e) {
        throw e;
    }
}

const useWorkflowLog = (resultId: string) => {
    return useQuery<WorkflowLog, AxiosError>({
        queryKey: ["workflowResult", resultId],
        queryFn: async () => getData(resultId),
        retry: false,
        suspense: false,
    })
}

export default useWorkflowLog;
