import * as React from "react"
import { GalleryVerticalEnd } from "lucide-react"

import {
    Sidebar,
    SidebarContent,
    SidebarGroup,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
    SidebarRail,
} from "@/components/ui/sidebar"
import {useNavigate} from "react-router-dom";

// This is sample data.
const data = {
    navMain: [
        {
            title: "Sitebunny",
            url: "#",
            items: [
                {
                    title: "Dashboard",
                    url: "dashboard",
                },
                {
                    title: "Workspace",
                    url: "channels",
                },
                {
                    title: "Chatbot",
                    url: "chatbots",
                },
                {
                    title: "Payment",
                    url: "payments",
                },
                {
                    title: "Account",
                    url: "user",
                },
                {
                    title: "Setting",
                    url: "setting",
                },
            ],
        },
        {
            title: "Workflow",
            url: "#",
            items: [

                {
                    title: "Workflow",
                    url: "workflow-service/workflows",
                },
                {
                    title: "Job",
                    url: "workflow-service/jobs",
                },
            ],
        },
    ],
}

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
    const navigate = useNavigate()
    const route = (path: string) => {
        navigate(path)
    }
    return (
        <Sidebar {...props}>
            <SidebarHeader>
                <SidebarMenu>
                    <SidebarMenuItem>
                        <SidebarMenuButton size="lg" asChild onClick={() => navigate('/dashboard')}>
                            <a href="/dashboard" onClick={(e) => e.preventDefault()}>
                                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                                    <GalleryVerticalEnd className="size-4" />
                                </div>
                                <div className="flex flex-col gap-0.5 leading-none">
                                    <span className="font-semibold">Chatbot Admin</span>
                                    <span className="">v1.0.0</span>
                                </div>
                            </a>
                        </SidebarMenuButton>
                    </SidebarMenuItem>
                </SidebarMenu>
            </SidebarHeader>
            <SidebarContent>
                <SidebarGroup>
                    <SidebarMenu>
                        {data.navMain.map((item) => (
                            <SidebarMenuItem key={item.title}>
                                <SidebarMenuButton asChild>
                                    <a href={item.url} className="font-medium">
                                        {item.title}
                                    </a>
                                </SidebarMenuButton>
                                {item.items?.length ? (
                                    <SidebarMenuSub>
                                        {item.items.map((item2) => (
                                            <SidebarMenuSubItem key={item2.title}>
                                                <SidebarMenuSubButton asChild>
                                                    <a href={item2.url} type='button'
                                                       onClick={(e) => {
                                                           e.preventDefault()
                                                           route(item2.url)
                                                       }}
                                                    >
                                                        {item2.title}
                                                    </a>
                                                </SidebarMenuSubButton>
                                            </SidebarMenuSubItem>
                                        ))}
                                    </SidebarMenuSub>
                                ) : null}
                            </SidebarMenuItem>
                        ))}
                    </SidebarMenu>
                </SidebarGroup>
            </SidebarContent>
            <SidebarRail />
        </Sidebar>
    )
}
