import React, {useEffect, useMemo} from "react";
import WorkflowGraph from "@/view/workflow/components/workflowGraph/WorkflowGraph";
import {useParams} from "react-router-dom";
import useWorkflow from "@/query/workflow/useWorkflow";
import {InputOperator, Operator} from "@/model/Workflow";
import {useRecoilValue, useSetRecoilState} from "recoil";
import editingOperatorState from "@/view/workflow/editingOperatorContext";
import OperatorForm from "@/view/workflow/components/operatorForm/OperatorForm";
import {ChevronsRight} from "lucide-react";
import OperatorIcon from "@/view/workflow/components/OperatorIcon";

interface WorkflowGraphViewProps {
}

const WorkflowGraphView: React.FC<WorkflowGraphViewProps> = () => {
    const {workflowId} = useParams<{ channelId: string, workflowId: string }>();
    const {data: workflow} = useWorkflow(workflowId!)

    const editingOperatorId = useRecoilValue(editingOperatorState);
    const setEditingOperator = useSetRecoilState(editingOperatorState);

    const editingOperator: undefined | Operator | InputOperator = useMemo(() => {
        if (!editingOperatorId || !workflow) return undefined

        if (editingOperatorId === 'input') {
            return {
                _id: "input",
                type: 'input',
                parameter: workflow.defaultParameter ?? {}
            }
        }
        return workflow.operators[editingOperatorId]

    }, [workflow, editingOperatorId])

    const operators = useMemo(() => {
        if (!workflow) return undefined
        const result = JSON.parse(JSON.stringify(workflow.operators))

        result['input'] = {
            _id: "input",
            type: 'input',
            parameter: workflow.defaultParameter,
            nextOperatorId: workflow.firstOperatorId
        } as InputOperator
        return result
    }, [workflow])


    useEffect(() => {
        return () => setEditingOperator(undefined)
    }, [setEditingOperator]);

    if (!operators || !workflow) return null

    return (
        <div className='h-full flex flex-col'>
            {editingOperator &&
                <div
                    className='rounded-md fixed z-10 gap-1 bg-background shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 h-[100%] right-0 bottom-0 border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right w-1/2 flex'>
                    <div className='grow-0 shrink-0 flex items-center hover:bg-gray-100 px-1'
                         role={'button'}
                         onClick={() => setEditingOperator(undefined)}>
                        <ChevronsRight/>
                    </div>
                    <div className='h-full overflow-y-auto flex flex-col w-full py-6'>
                        <div className='mb-3 flex items-center space-x-3'>
                            <OperatorIcon type={editingOperator.type}/>
                            <h2 className='font-semibold'>{editingOperator.type}</h2>

                        </div>
                        <div className='py-3 grow'>
                            <OperatorForm operator={editingOperator}
                                          workflow={workflow}
                            />
                        </div>

                    </div>

                </div>
            }
            <WorkflowGraph operators={operators}
                           firstNodeId={workflow.firstOperatorId}
                           editable={true}
            />
        </div>
    )
}

export default WorkflowGraphView