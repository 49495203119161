"use client";
import React, {useCallback, useMemo} from "react";
import {Handle, Node, NodeProps, useNodeId, useReactFlow,} from "@xyflow/react";
import {Copy, Pencil, Play, Trash} from "lucide-react";
import {
    NodeHeader,
    NodeHeaderAction,
    NodeHeaderActionProps,
    NodeHeaderActions,
    NodeHeaderIcon,
    NodeHeaderTitle
} from "@/view/workflow/components/NodeHeader";
import editingOperatorState from "@/view/workflow/editingOperatorContext";
import {useSetRecoilState} from "recoil";
import {BaseNode} from "@/view/workflow/components/workflowGraph/BaseNode";
import OperatorIcon from "@/view/workflow/components/OperatorIcon";

function OperatorNode(props: NodeProps<Node<{ title: string; label: string, editable: boolean, borderColor?: string, borderWidth?: number, duration?: number, showDuration?: boolean }>>) {
    const {data, sourcePosition, targetPosition} = props

    const className = useMemo(() => {
        let result = "px-3 py-2"

        if (data.borderColor) {
            result += ` border-${data.borderColor}`
        }

        if (data.borderWidth) {
            result += ` border-${data.borderWidth}`
        } else {
            result += ` border-2`
        }
        return result
    }, [data.borderColor, data.borderWidth])
    return (
        <BaseNode className={className}>
            <NodeHeader className="-mx-3 -mt-2 border-b">
                <NodeHeaderIcon>
                    <OperatorIcon type={data.label}/>
                </NodeHeaderIcon>
                <NodeHeaderTitle>
                    {data.title}
                </NodeHeaderTitle>
                <NodeHeaderActions>
                    {/*<NodeHeaderMenuAction label="Expand account options">*/}
                    {/*    <DropdownMenuLabel>My Account</DropdownMenuLabel>*/}
                    {/*    <DropdownMenuSeparator />*/}
                    {/*    <DropdownMenuItem>Profile</DropdownMenuItem>*/}
                    {/*    <DropdownMenuItem>Billing</DropdownMenuItem>*/}
                    {/*    <DropdownMenuItem>Team</DropdownMenuItem>*/}
                    {/*    <DropdownMenuItem>Subscription</DropdownMenuItem>*/}
                    {/*</NodeHeaderMenuAction>*/}
                    {/*<NodeHeaderCopyAction label={'qwef'}/>*/}
                    {/*{data.label === 'input' && <NodeHeaderRunAction label={'run'}/>}*/}
                    {data.editable && <NodeHeaderEditAction label={'edit'}/>}
                    {/*<NodeHeaderDeleteAction />*/}
                </NodeHeaderActions>
                <span className='ml-2 text-black'>{data.showDuration && `${data.duration ?? 0}ms`}</span>
            </NodeHeader>
            {targetPosition && data.label !== 'input' && <Handle type="target" position={targetPosition} />}
            {sourcePosition && <Handle type="source" position={sourcePosition} />}
            <div className="text-sm">{data.label}</div>
        </BaseNode>
    );
}

const NodeHeaderDeleteAction = () => {
    const id = useNodeId();
    const { setNodes } = useReactFlow();

    const handleClick = useCallback(() => {
        setNodes((prevNodes) =>
            prevNodes.filter((node) => {
                if (node.id === id) {
                    window.setTimeout(() => {
                        setNodes((prevNodes) => [...prevNodes, node]);
                    }, 2000);

                    return false;
                }

                return true;
            }),
        );
    }, [id, setNodes]);

    return (
        <NodeHeaderAction onClick={handleClick} variant="ghost" label="Delete node">
            <Trash />
        </NodeHeaderAction>
    );
};

NodeHeaderDeleteAction.displayName = "NodeHeaderDeleteAction";

export interface NodeHeaderCopyActionProps
    extends Omit<NodeHeaderActionProps, "onClick"> {
    onClick?: (nodeId: string, event: React.MouseEvent) => void;
}

export const NodeHeaderCopyAction = React.forwardRef<
    HTMLButtonElement,
    NodeHeaderCopyActionProps
>(({ onClick, ...props }, ref) => {
    const id = useNodeId();

    const handleClick = useCallback(
        (event: React.MouseEvent) => {
            if (!onClick || !id) return;

            onClick(id, event);
        },
        [id, onClick],
    );

    return (
        <NodeHeaderAction
            ref={ref}
            onClick={handleClick}
            variant="ghost"
            {...props}
        >
            <Copy />
        </NodeHeaderAction>
    );
});

NodeHeaderCopyAction.displayName = "NodeHeaderCopyAction";

export interface NodeHeaderEditActionProps
    extends Omit<NodeHeaderActionProps, "onClick"> {
    // onClick?: (nodeId: string, event: React.MouseEvent) => void;
}


export const NodeHeaderEditAction = React.forwardRef<
    HTMLButtonElement,
    NodeHeaderCopyActionProps
>(({ onClick, label, ...props }, ref) => {
    const id = useNodeId();

    const setEditingOperator = useSetRecoilState(editingOperatorState);

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();

            if (!id) return;
            setEditingOperator(id)
        },
        [id, setEditingOperator],
    );

    return (
        <NodeHeaderAction
            ref={ref}
            onClick={handleClick}
            variant="ghost"
            label={label}
            {...props}
        >
            <Pencil />
        </NodeHeaderAction>
    );
});

export const NodeHeaderRunAction = React.forwardRef<
    HTMLButtonElement,
    NodeHeaderCopyActionProps
>(({ onClick, label, ...props }, ref) => {
    const id = useNodeId();

    const setEditingOperator = useSetRecoilState(editingOperatorState);

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();

            if (!id) return;
            setEditingOperator(id)
        },
        [id, setEditingOperator],
    );

    return (
        <NodeHeaderAction
            ref={ref}
            onClick={handleClick}
            variant="ghost"
            label={label}
            {...props}
        >
            <Play/>
        </NodeHeaderAction>
    );
});

NodeHeaderEditAction.displayName = "NodeHeaderEditAction";

export default OperatorNode
