import {
    BranchPythonOperator,
    InputOperator,
    LLMOperator,
    Operator, OperatorTypeEnum,
    PythonOperator, SearchFAQOperator,
    SearchRAGOperator,
    Workflow
} from "@/model/Workflow";
import React, {useMemo} from "react";
import PythonOperatorForm from "@/view/workflow/components/operatorForm/PythonOperatorForm";
import PythonBranchOperatorForm from "@/view/workflow/components/operatorForm/PythonBranchOperatorForm";
import LLMOperatorForm from "@/view/workflow/components/operatorForm/LLMOperatorForm";
import InputOperatorForm from "@/view/workflow/components/operatorForm/InputOperatorForm";
import SearchRAGOperatorForm from "@/view/workflow/components/operatorForm/SearchRAGOperatorForm";
import SearchFAQOperatorForm from "@/view/workflow/components/operatorForm/SearchFAQOperatorForm";

interface OperatorDetailProps {
    workflow: Workflow
    className?: string
    operator: Operator | InputOperator
    readonly?: boolean
}

const OperatorForm: React.FC<OperatorDetailProps> = ({operator, workflow, readonly}) => {

    // console.log(operator)
    const operatorForm = useMemo(() => {
        if (operator?.type === OperatorTypeEnum.PYTHON) {
            return <PythonOperatorForm key={`operator-${operator._id}`}
                                       operator={operator as PythonOperator}
                                       readOnly={readonly}
            />
        } else if (operator?.type === OperatorTypeEnum.BRANCH_PYTHON) {
            return <PythonBranchOperatorForm key={`operator-${operator._id}`}
                                             operator={operator as BranchPythonOperator}
                                             readOnly={readonly}
            />
        } else if (operator?.type === OperatorTypeEnum.LLM) {
            return <LLMOperatorForm key={`operator-${operator._id}`}
                                    operator={operator as LLMOperator}
                                    readOnly={readonly}
            />
        } else if (operator?.type === 'input') {
            return <InputOperatorForm key={`operator-${operator._id}`}
                                      workflow={workflow}
                                      operator={operator as InputOperator}
                                      readOnly={readonly}
            />
        } else if (operator?.type === OperatorTypeEnum.SEARCH_DATA_SOURCE_RAG) {
            return <SearchRAGOperatorForm key={`operator-${operator._id}`}
                                          operator={operator as SearchRAGOperator}
                                          readOnly={readonly}
            />
        } else if (operator?.type === OperatorTypeEnum.SEARCH_DATA_SOURCE_FAQ) {
            return <SearchFAQOperatorForm key={`operator-${operator._id}`}
                                           operator={operator as SearchFAQOperator}
                                           readOnly={readonly}
            />
        }
    }, [operator, readonly, workflow])

    if (operatorForm) {
        return (operatorForm)
    } else {
        return null
    }
}


export default OperatorForm