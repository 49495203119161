import {Button} from "@/components/ui/button"
import {Workflow} from "@/model/Workflow";
import useModal, {Modal} from "@/hook/useModal";
import toast from "react-hot-toast";
import useCloneWorkflow from "@/query/workflow/useCloneWorkflow";
import {Input} from "@/components/ui/input";
import {useRef} from "react";
import {useNavigate} from "react-router-dom";

export const CLONE_WORKFLOW_MODAL_ID = "clone-workflow";


interface WorkflowSaveDialogProps {
    workflow: Workflow
}

const WorkflowCloneDialog: React.FC<WorkflowSaveDialogProps> = ({workflow}) => {

    const modal = useModal()
    const navigate = useNavigate();
    const nameInputRef = useRef<HTMLInputElement>(null);
    const {mutateAsync: cloneWorkflow, isLoading} = useCloneWorkflow(workflow._id)

    const onSubmit = async () => {
        if (isLoading || !nameInputRef.current) return

        if (!nameInputRef.current.value) {
            toast.error("이름은 필수")
            return
        }
        const loadingToastId = toast.loading("Cloning...")
        try {
            const result = await cloneWorkflow(nameInputRef.current.value);
            navigate(`/workflow-service/workflows/${result._id}`)
            toast.success("Success", {id: loadingToastId})
        } catch (e) {
            console.log(e)
            toast.error("error", {id: loadingToastId});
        } finally {
            close()
        }
    }
    const close = () => modal.close(CLONE_WORKFLOW_MODAL_ID)

    return (
        <Modal id={CLONE_WORKFLOW_MODAL_ID}
               size="sm"
               title={'Clone Workflow'}
        >
            <div className='pt-3'>
                <div className="flex flex-col space-y-1.5 pl-2">
                    <Input id="passageSize"
                           ref={nameInputRef}
                           placeholder="Workflow Name"
                    />
                </div>
            </div>
            <div className="flex justify-end space-x-2 mt-2">
                <Button variant="outline"
                        onClick={close}
                >
                    Cancel
                </Button>
                <Button onClick={onSubmit} disabled={isLoading}>
                    Clone
                </Button>
            </div>
        </Modal>
    )
}

export default WorkflowCloneDialog
