import axios from "axios";
import {WOKRFLOW_API_HOST} from "@/core/variables";
import {Workflow} from "@/model/Workflow";

class WorkflowRepository {
    async read(workflowId: string) {
        return axios.get(`${WOKRFLOW_API_HOST}/v1/workflows/${workflowId}`)
    };

    async paginate(props: {page: number, size: number, name?: string, labels?: string[]}) {
        return axios.get(`${WOKRFLOW_API_HOST}/v1/workflows/paginate`, {
            params: {page: props.page, size: props.size, name: props.name, labels: props.labels? JSON.stringify(props.labels): undefined}
        })
    };

    async find(workflowIds: string[]) {
        return axios.get(`${WOKRFLOW_API_HOST}/v1/workflows`, {
            params: {workflowIds: workflowIds.join(',')}
        })
    };

    async update(workflow: Workflow) {
        return axios.put(`${WOKRFLOW_API_HOST}/v1/workflows/${workflow._id}`, {
            workflow
        })
    };

    async run(workflowUserId: string, workflowId: string, params: object) {
        return axios.post(`${WOKRFLOW_API_HOST}/v1/workflows/${workflowId}/run`, {
            userId: workflowUserId,
            params
        })
    };

    async clone(workflowId: string, workflowName: string) {
        return axios.post(`${WOKRFLOW_API_HOST}/v1/workflows/${workflowId}/clone`, {
            workflowName
        })
    };


}
const workflowRepository = new WorkflowRepository();
export default workflowRepository
