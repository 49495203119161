import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import useWorkflows from "@/query/workflow/useWorkflows";
import {Workflow} from "@/model/Workflow";
import _ from "lodash";
import workflowRepository from "@/respsitory/workflowRepository";
import Combobox from "@/components/ui/combobox";

export interface WorkflowChatHandlerComboBoxRef {
    chatHandler: () => Workflow | undefined
}

interface WorkflowChatHandlerComboBoxProps {
    defaultWorkflowId?: string
    labels?: string[]
}

export const WorkflowComboBox = forwardRef<WorkflowChatHandlerComboBoxRef, WorkflowChatHandlerComboBoxProps>(({
                                                                                                                  defaultWorkflowId,
                                                                                                                  labels
                                                                                                              }, ref) => {

    const [workflowName, setWorkflowName] = useState<string>("")
    const {
        data,
    } = useWorkflows({name: workflowName, labels: labels})

    const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | undefined>(undefined)

    const workflows = useMemo(() => {
        return data?.pages.map((page) => page.content).flat()
    }, [data])

    const onChange = _.debounce((search: string) => {
        setWorkflowName(search);
    }, 300);


    useEffect(() => {

        if (!defaultWorkflowId) return

        workflowRepository.find([defaultWorkflowId]).then(({data}) => {
            const chatWorkflow = data.result.find((workflow: Workflow) => workflow._id === defaultWorkflowId)
            setSelectedWorkflow(chatWorkflow)
        })

    }, [defaultWorkflowId])


    useImperativeHandle(ref, () => {
        return {
            chatHandler: () => {
                return selectedWorkflow
            },
        }
    }, [selectedWorkflow]);

    return (
        <Combobox placeholder={selectedWorkflow
            ? selectedWorkflow.name
            : "Select Workflow..."}
                  onChangeQuery={onChange}
                  items={workflows ? workflows.map((workflow) => ({
                      label: workflow.name || '',
                      value: workflow._id
                  })) : []}
                  onChangeValue={(selectedWorkflowId) => {

                      const _selectedWorkflow = workflows?.find((workflow) => workflow._id === selectedWorkflowId)
                      if (_selectedWorkflow) {
                          setSelectedWorkflow(_selectedWorkflow)
                      }
                  }}
        />
    )
})