import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "@/components/ui/table"
import React, {useMemo} from "react";
import VisibilitySensor from "@/components/ui/sensor";
import {formatIsoReadable} from "@/utils/dateUtils";
import useWorkflows from "@/query/workflow/useWorkflows";
import {Sheet, SheetTrigger} from "@/components/ui/sheet";
import {useNavigate} from "react-router-dom";

export const WorkflowList: React.FC<{ groupId?: string }> = () => {

    const navigate = useNavigate();
    const {
        data,
        hasNextPage,
        fetchNextPage,
        isLoading,
    } = useWorkflows()
    // const {data: channelsResponse} = useChannels(1, 20, SearchTypeEnum.CHANNELNAME, channelNameQuery, false);
    // const {data: channelResponse} = useChannel(channelId)
    const onScrollAtEnd = async () => {
        if (hasNextPage && !isLoading) {
            await fetchNextPage();
        }
    }

    const workflows = useMemo(() => {
        return data?.pages.map((page) => page.content).flat()
    }, [data])

    // const onChangeChannelNameQuery = (channelName?: string ) => {
    //     setChannelNameQuery(channelName)
    // };
    //
    // const setChannelId = (value?: string) => {
    //     if (!value || channelId === value) {
    //         navigate(`/jobs`)
    //     } else {
    //         navigate(`/jobs?channelId=${value}`)
    //     }
    // }

    return (
        <div>
            {/*<div className='flex items-center mb-4 gap-2'>*/}
            {/*    <Select key={key} value={status} onValueChange={onChangeSelect}>*/}
            {/*        <SelectTrigger className="w-[180px]">*/}
            {/*            <SelectValue placeholder="Select status"/>*/}
            {/*        </SelectTrigger>*/}
            {/*        <SelectContent>*/}
            {/*            <SelectGroup>*/}
            {/*                <SelectItem value={'done'}>Done</SelectItem>*/}
            {/*                <SelectItem value={'failed'}>Failed</SelectItem>*/}
            {/*                {status &&*/}
            {/*                    <Button variant={'ghost'}*/}
            {/*                            className='w-full text-left pl-2 justify-start'*/}
            {/*                            onClick={(e) => {*/}
            {/*                                e.stopPropagation()*/}
            {/*                                setStatus(undefined)*/}
            {/*                                setKey(+new Date())*/}
            {/*                            }}*/}
            {/*                    >All*/}
            {/*                    </Button>*/}
            {/*                }*/}
            {/*            </SelectGroup>*/}
            {/*        </SelectContent>*/}
            {/*    </Select>*/}
            {/*    <DownloadButton groupId={groupId} size={20}/>*/}
            {/*</div>*/}

            <Table>
                {/*<TableCaption>A list of your recent invoices.</TableCaption>*/}
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[20px] text-center"></TableHead>
                        <TableHead className="w-[100px] text-center">Name</TableHead>
                        <TableHead className='text-center'>Labels</TableHead>
                        <TableHead className='text-center'>Description</TableHead>
                        <TableHead className='text-right'>Created At</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {workflows?.map((workflow, index) => (
                        <Sheet key={`log-${workflow._id}`}>
                            <SheetTrigger asChild>
                                <TableRow key={workflow._id}
                                          onClick={() => navigate(`/workflow-service/workflows/${workflow._id}`)}
                                          role={'button'}
                                          className='border-0'>
                                    <>
                                        <TableCell className="font-medium text-center">{index + 1}</TableCell>
                                        <TableCell className="font-medium text-center">{workflow.name}</TableCell>
                                        {/*<TableCell className="font-medium">{job.channelName}</TableCell>*/}
                                        <TableCell className="font-medium text-center">
                                            {workflow.labels?.join(',')}
                                        </TableCell>
                                        <TableCell className="font-medium text-left">
                                            {workflow.description}
                                        </TableCell>
                                        <TableCell className="font-medium text-right">
                                            {formatIsoReadable(workflow.createdAt)}
                                        </TableCell>
                                    </>
                                </TableRow>
                            </SheetTrigger>
                            {/*<LogSheetContent log={log}/>*/}
                        </Sheet>
                    ))}

                    {workflows?.length === 0 &&
                        <TableRow className='border-0'><TableCell colSpan={4}>No result.</TableCell></TableRow>}

                    {!isLoading &&
                        <tr>
                            <td>
                                <VisibilitySensor onEnter={onScrollAtEnd}/>
                            </td>
                        </tr>
                    }

                </TableBody>

                {/*<TableFooter>*/}
                {/*    <TableRow>*/}
                {/*        <TableCell colSpan={3}>Total</TableCell>*/}
                {/*        <TableCell className="text-right">$2,500.00</TableCell>*/}
                {/*    </TableRow>*/}
                {/*</TableFooter>*/}
            </Table>

        </div>
    )

}

// const DownloadButton: React.FC<{ groupId?: string, size: number }> = ({groupId, size = 20}) => {
//     const [page, setPage] = useState<undefined | number>(undefined)
//     const workbookRef = useRef<ExcelJS.Workbook | undefined>(undefined)
//     const worksheetRef = useRef<ExcelJS.Worksheet | undefined>(undefined)
//
//     const start = () => {
//         if (workbookRef.current) return
//
//         workbookRef.current = new ExcelJS.Workbook();
//         worksheetRef.current = workbookRef.current.addWorksheet('sheet')
//         setPage(1)
//     }
//
//     const download = useCallback(async () => {
//         if (workbookRef.current === undefined || worksheetRef.current === undefined) return
//
//         const buffer = await workbookRef.current.xlsx.writeBuffer();
//
//         new Blob([buffer])
//         saveAs(new Blob([buffer]), 'workflow_logs.xlsx');
//
//         workbookRef.current = undefined
//         worksheetRef.current = undefined
//
//     }, [])
//
//     const loadRows = useCallback(async (_page: number) => {
//         const {data} = await logRepository.paginate(_page, size, groupId, 'done')
//
//         const logs: WorkflowLog[] = data.result.content
//         return logs.reverse().map((log) => logToRow(log))
//     }, [groupId, size])
//
//     function sleep(ms: number) {
//         return new Promise(resolve => setTimeout(resolve, ms));
//     }
//
//     useEffect(() => {
//         if (page !== undefined ) {
//             sleep(1000).then(() => {
//                 if (page === undefined || worksheetRef.current === undefined) return
//                 return loadRows(page)
//
//             }).then((rows) => {
//                 if (rows === undefined || page === undefined || worksheetRef.current === undefined) return
//
//                 if (page === 1 && rows.length > 0) {
//                     worksheetRef.current.columns = Object.keys(rows[0]).map((column) => ({
//                         header: column,
//                         key: column
//                     }))
//                 }
//                 worksheetRef.current.addRows(rows)
//                 if (rows.length < size) {
//                     setPage(undefined)
//                 } else {
//                     setPage((prev) => (prev! + 1))
//                 }
//             }).finally()
//
//         } else {
//             download().catch()
//         }
//     }, [size, page, loadRows, download])
//
//     const logToRow = (log: WorkflowLog): { [key: string]: string } => {
//         const row = log.params ? {...log.params} : {};
//         const operatorOutlets = log.operatorOutlets;
//
//         // FAQ
//         const operator6 = getOperator("search-faq", operatorOutlets);
//         if (operator6) {
//             const outlet = operator6.outlet;
//             row["FAQ Record Id"] = outlet?.faqId || null
//         } else {
//             row["FAQ Record Id"] = null
//         }
//
//         // Query Analyze
//         const operator1 = getOperator("query-analyze", operatorOutlets);
//         if (operator1) {
//             const outlet = operator1.outlet;
//             row["Query Analyze(Prompt)"] = indentValue(operator1.prompts)
//             row["Query Analyze"] = indentValue(indentValue(outlet.messages))
//
//         } else {
//             row["Query Analyze(Prompt)"] = null
//             row["Query Analyze"] = null
//         }
//
//         // Document Search
//         const operator2 = getOperator("document-search", operatorOutlets);
//         if (operator2) {
//             const outlet = operator2.outlet;
//             const resultType = outlet.type;
//
//             if (resultType === "passages" || resultType === "mrc") {
//                 const passages = outlet.passages || [];
//                 for (let i = 0; i < 3; i++) {
//                     if (passages[i]) {
//                         row[`record_id.${i+1}`] = passages[i].recordId
//                         row[`title.${i+1}`] = passages[i].title
//                         row[`title_link.${i+1}`] = passages[i].titleLink
//                         row[`Result Passage.${i+1}`] = resultType === "passages" ? passages[i].content : passages[i].passage
//                     } else {
//                         row[`record_id.${i+1}`] = null
//                         row[`title.${i+1}`] = null
//                         row[`title_link.${i+1}`] = null
//                         row[`Result Passage.${i+1}`] = null
//                     }
//                 }
//             } else {
//                 for (let i = 0; i < 3; i++) {
//                     row[`record_id.${i+1}`] = null
//                     row[`title.${i+1}`] = null
//                     row[`title_link.${i+1}`] = null
//                     row[`Result Passage.${i+1}`] = null
//                 }
//             }
//             row["Document Search Type"] = resultType
//         } else {
//             for (let i = 0; i < 3; i++) {
//                 row[`record_id.${i+1}`] = null
//                 row[`title.${i+1}`] = null
//                 row[`title_link.${i+1}`] = null
//                 row[`Result Passage.${i+1}`] = null
//             }
//             row["Document Search Type"] = null
//         }
//
//         // Generate Answer
//         const operator3 = getOperator("generate-answer", operatorOutlets);
//         if (operator3) {
//             const outlet = operator3.outlet;
//             row['Generate Answer(Prompt)'] = indentValue(operator3.prompts)
//             row['Generate Answer(LLM)'] = indentValue(outlet.messages)
//         } else {
//             row['Generate Answer(Prompt)'] = null
//             row['Generate Answer(LLM)'] = null
//         }
//
//         // Refine Answer
//         const operator4 = getOperator("refine-answer", operatorOutlets);
//         if (operator4) {
//             const outlet = operator4.outlet;
//             row["Result Answer"] = outlet.answer
//             row["Result Reference"] = outlet.reference
//             row["Result Suggested Question"] = outlet.question_list
//         } else {
//             row["Result Answer"] = null
//             row["Result Reference"] = null
//             row["Result Suggested Question"] = null
//         }
//         return row
//     }
//     return (
//         <DropdownMenu>
//             <DropdownMenuTrigger>
//                 <Button variant="outline">Download</Button>
//             </DropdownMenuTrigger>
//             <DropdownMenuContent>
//                 <DropdownMenuItem disabled={true}>일반</DropdownMenuItem>
//                 {groupId && <DropdownMenuItem onClick={start}>기획 질의 포맷</DropdownMenuItem> }
//             </DropdownMenuContent>
//         </DropdownMenu>
//
//     )
// }

export default WorkflowList;