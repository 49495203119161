import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "@/components/ui/table"
import React, {useImperativeHandle, useMemo} from "react";
import VisibilitySensor from "@/components/ui/sensor";
import {formatIsoReadable} from "@/utils/dateUtils";
import {Badge} from "@/components/ui/badge";
import useWorkflowLogs from "@/query/workflowLog/useWorkflowLogs";
import {WorkflowLog, WorkflowLogStatus} from "@/model/Log";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger} from "@/components/ui/sheet";
import moment from "moment";
import {useQueryClient} from "@tanstack/react-query";
import WorkflowLogViewer from "@/view/workflowLog/component/WorkflowLogViewer";

interface WorkflowLogListProps {
    status?: WorkflowLogStatus
    workflowIds?: string[]
    groupId?: string
    showDownload?: boolean
}

export interface WorkflowLogListRef {
    invalidate: () => void
}

export const WorkflowLogList = React.forwardRef<WorkflowLogListRef, WorkflowLogListProps>(({status, workflowIds, groupId, showDownload = true}, ref) => {

    const queryClient = useQueryClient()
    const {
        data,
        hasNextPage,
        fetchNextPage,
        isLoading,
    } = useWorkflowLogs({groupId, status, workflowIds})
    // const {data: channelsResponse} = useChannels(1, 20, SearchTypeEnum.CHANNELNAME, channelNameQuery, false);
    // const {data: channelResponse} = useChannel(channelId)
    const onScrollAtEnd = async () => {
        if (hasNextPage && !isLoading) {
            await fetchNextPage();
        }
    }

    useImperativeHandle(ref, () => {
        return {
            invalidate: () =>  {
                queryClient.invalidateQueries(["workflow", "logs", groupId, workflowIds, status], {exact: true})
            },
        }
    }, [groupId, queryClient, status, workflowIds])

    const logs = useMemo(() => {
        return data?.pages.map((page) => page.content).flat()
    }, [data])

    // const onChangeChannelNameQuery = (channelName?: string ) => {
    //     setChannelNameQuery(channelName)
    // };
    //
    // const setChannelId = (value?: string) => {
    //     if (!value || channelId === value) {
    //         navigate(`/jobs`)
    //     } else {
    //         navigate(`/jobs?channelId=${value}`)
    //     }
    // }

    return (
        <div className='p-2 h-full overflow-y-auto'>

            <Table>
                {/*<TableCaption>A list of your recent invoices.</TableCaption>*/}
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[20px] text-center"></TableHead>
                        <TableHead className="w-[100px] text-center">Workflow</TableHead>
                        <TableHead className='text-center'>Status</TableHead>
                        <TableHead className='text-center'>User</TableHead>
                        <TableHead className='text-right'>Created At</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {logs?.map((log, index) => (
                        <Sheet key={`log-${log._id}`}>
                            <SheetTrigger asChild>
                                <TableRow key={log._id} role={'button'} className='border-0'>
                                    <>
                                        <TableCell className="font-medium text-center">{index + 1}</TableCell>
                                        <TableCell className="font-medium text-center">{log.workflowName}</TableCell>
                                        {/*<TableCell className="font-medium">{job.channelName}</TableCell>*/}
                                        <TableCell className="font-medium text-center">
                                            {statusBadge(log)}
                                        </TableCell>
                                        <TableCell className="font-medium text-center">
                                            {log.user.name}
                                        </TableCell>
                                        <TableCell className="font-medium text-right">
                                            {formatIsoReadable(log.createdAt)}
                                        </TableCell>
                                    </>
                                </TableRow>
                            </SheetTrigger>
                            <LogSheetContent log={log}/>
                        </Sheet>
                    ))}

                    {logs?.length === 0 &&
                        <TableRow className='border-0'><TableCell colSpan={4}>No result.</TableCell></TableRow>}

                    {!isLoading &&
                        <tr>
                            <td>
                                <VisibilitySensor onEnter={onScrollAtEnd}/>
                            </td>
                        </tr>
                    }

                </TableBody>

                {/*<TableFooter>*/}
                {/*    <TableRow>*/}
                {/*        <TableCell colSpan={3}>Total</TableCell>*/}
                {/*        <TableCell className="text-right">$2,500.00</TableCell>*/}
                {/*    </TableRow>*/}
                {/*</TableFooter>*/}
            </Table>

        </div>
    )

})

export const statusBadge = (log: WorkflowLog) => {
    if (log.status === 'done') {
        return <Badge variant="outline">Done</Badge>
    } else if (log.status === 'failed') {
        return <Badge variant="destructive">Failed</Badge>
    }
}

const LogSheetContent: React.FC<{ log: WorkflowLog }> = ({log}) => {

    const layout = localStorage.getItem("react-resizable-panels:layout:workflow-log")

    const defaultLayout = layout ? JSON.parse(layout) : undefined

    return (
        <SheetContent
            className="w-[90%] sm:w-[90%] sm:max-w-[90%] h-screen flex flex-col"
        >
            <SheetHeader className='flex'>
                <SheetTitle className='flex items-center'>
                    <span className='mr-2'>{log.workflowName}</span>
                    {statusBadge(log)}
                </SheetTitle>
                <SheetDescription>
                    <span className='text-xs text-slate-500 mr-5'>Duration (ms): {moment.utc(log.createdAt).diff(moment.utc(log.startedAt), 'milliseconds').toLocaleString("ko-KR")}</span>
                    <span className='text-xs text-slate-500 mr-5'>Started At: {formatIsoReadable(log.startedAt)}</span>
                    <span className='text-xs text-slate-500 mr-5'>Ended At: {formatIsoReadable(log.createdAt)}</span>

                </SheetDescription>
            </SheetHeader>

            <div className='rounded-xl border bg-card text-card-foreground shadow w-full h-full overflow-hidden'>
                <WorkflowLogViewer
                    className='grow'
                    log={log}
                    defaultLayout={defaultLayout}
                />
            </div>

            {/*<SheetFooter>*/}
            {/*    {job.status === 'running' &&*/}
            {/*        <Button type="submit" variant='destructive' onClick={interrupt}>*/}
            {/*            Interrupt*/}
            {/*        </Button>*/}
            {/*    }*/}
            {/*    <Button type="submit" variant='destructive' onClick={interrupt}>*/}
            {/*        Interrupt*/}
            {/*    </Button>*/}
            {/*    /!*<SheetClose asChild>*!/*/}
            {/*    /!*    <Button type="submit">Save changes</Button>*!/*/}
            {/*    /!*</SheetClose>*!/*/}
            {/*</SheetFooter>*/}
        </SheetContent>
    )
}



export default WorkflowLogList;