import chatbotRepository from "../../respsitory/ChatbotRepository";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Chatbot} from "@/model/Chatbot";


const getData = async (chatbotId: string) => {
    try {
        const {data} = await chatbotRepository.read(chatbotId)
        return data.result
    } catch (e) {
        throw e;
    }
}

const useChatbot = (chatbotId: string) => {
    return useQuery<Chatbot, AxiosError>({
        queryKey: ["chatbot", chatbotId],
        queryFn: async () => getData(chatbotId),
        suspense: true,
        refetchOnMount: true,
        useErrorBoundary: false,
    })
}

export default useChatbot;
