import React from "react";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {Button} from "@/components/ui/button";
import {WorkflowLogStatus} from "@/model/Log";
import {useParams} from "react-router-dom";
import WorkflowJobDownloadButton from "@/view/workflowJob/components/WorkflowJobDownloadButton";
import WorkflowLogList from "@/view/workflowLog/workflowLogList";

const WorkflowJobView = () => {
    const { jobId } = useParams<{jobId: string}>();
    const [key, setKey] = React.useState(+new Date())
    const [status, setStatus] = React.useState<WorkflowLogStatus
        | undefined>(undefined);
    // const {data: channelsResponse} = useChannels(1, 20, SearchTypeEnum.CHANNELNAME, channelNameQuery, false);
    // const {data: channelResponse} = useChannel(channelId)


    // const onChangeChannelNameQuery = (channelName?: string ) => {
    //     setChannelNameQuery(channelName)
    // };
    //
    // const setChannelId = (value?: string) => {
    //     if (!value || channelId === value) {
    //         navigate(`/jobs`)
    //     } else {
    //         navigate(`/jobs?channelId=${value}`)
    //     }
    // }

    const onChangeSelect = (value: WorkflowLogStatus) => {
        setStatus(value)
    }

    if (!jobId) return null
    return (
        <div className='p-2'>
            <div className='flex items-center mb-4 gap-2'>
                <Select key={key} value={status} onValueChange={onChangeSelect}>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Select status"/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem value={'done'}>Done</SelectItem>
                            <SelectItem value={'failed'}>Failed</SelectItem>
                            {status &&
                                <Button variant={'ghost'}
                                        className='w-full text-left pl-2 justify-start'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setStatus(undefined)
                                            setKey(+new Date())
                                        }}
                                >All
                                </Button>
                            }
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <WorkflowJobDownloadButton groupId={jobId} size={20} status={status}/>
            </div>
            <WorkflowLogList groupId={jobId} status={status}/>
        </div>
    )
}

export default WorkflowJobView