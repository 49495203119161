import React, {useRef} from "react";
import {useParams} from "react-router-dom";
import useWorkflow from "@/query/workflow/useWorkflow";
import {Workflow} from "@/model/Workflow";
import {Label} from "@/components/ui/label";
import {Button} from "@/components/ui/button";
import toast from "react-hot-toast";
import {Input} from "@/components/ui/input";
import {Textarea} from "@/components/ui/textarea";
import useUpdateWorkflow from "@/query/workflow/useUpdateWorkflow";

const WorkflowMetaView = () => {
    const {workflowId} = useParams<{ channelId: string, workflowId: string }>();
    const {data: workflow} = useWorkflow(workflowId!)

    if (!workflow) return null
    return (
        <Content workflow={workflow}/>
    )
}

const Content: React.FC<{workflow: Workflow}> = ({workflow}) => {
    const {mutateAsync: updateWorkflow, isLoading} = useUpdateWorkflow(workflow._id)

    const nameRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);

    const onSubmit = async () => {
        if (isLoading || !nameRef.current || !descriptionRef.current) return

        if (!nameRef.current.value) {
            toast.error("이름은 필수");
            return
        }

        const workflowV2 = {
            ...workflow,
            name: nameRef.current.value,
            description: descriptionRef.current.value
        }

        const loadingToastId = toast.loading("logging in")
        try {
            await updateWorkflow(workflowV2);
            toast.success("Success", {id: loadingToastId})
        } catch (e) {
            console.log(e)
            toast.error("error", {id: loadingToastId});
        }
    }
    return (
        <div className='flex flex-col h-full p-5 space-y-3 max-w-[800px]'>
            <div className="flex flex-col space-y-1.5 pl-2">
                <Label htmlFor="name"> Name</Label>
                <Input id="name"
                       ref={nameRef}
                       placeholder="Name of Workflow"
                       defaultValue={workflow.name}
                    // disabled={readOnly}
                    // onChange={_onChangeName}
                />
            </div>

            <div className="flex flex-col space-y-1.5 pl-2">
                <Label htmlFor="name">Description</Label>
                <Textarea defaultValue={workflow.description}
                          ref={descriptionRef}
                          placeholder="Memo"
                          rows={8}
                />
            </div>

            <div className="flex justify-end space-x-2">
                <Button onClick={onSubmit} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </div>
    )
}
export default WorkflowMetaView