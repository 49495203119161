import * as React from "react"
import {useMemo, useState} from "react"
import {ResizableHandle, ResizablePanel, ResizablePanelGroup} from "@/components/ui/resizable";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";
import {TooltipProvider} from "@/components/ui/tooltip";
import {OperatorOutlet, WorkflowLog} from "@/model/Log";
import {OperatorOutletDetail} from "@/view/workflowLog/OperatorOutletDetail";
import classNames from "classnames";
import WorkflowGraph from "@/view/workflow/components/workflowGraph/WorkflowGraph";
import useWorkflow from "@/query/workflow/useWorkflow";
import moment from "moment";
import OperatorForm from "@/view/workflow/components/operatorForm/OperatorForm";
import OperatorIcon from "@/view/workflow/components/OperatorIcon";
import {InputOperator, Operator} from "@/model/Workflow";
import Editor from "@monaco-editor/react";

interface WorkflowLogViewerProps {
    className?: string
    log: WorkflowLog
    defaultLayout: number[] | undefined
}

const WorkflowLogViewer: React.FC<WorkflowLogViewerProps> = ({
                                                                 className,
                                                                 log,
                                                                 defaultLayout = [52, 48],
                                                             }) => {

    const {data: workflow} = useWorkflow(log.workflowId)

    const [selectedOperatorId, setSelectedOperatorId] = useState<string>(log.resultOperatorId)

    const focusedInfo: undefined | { type: "input", params: any } | {
        type: "operator",
        operator: Operator,
        outlet?: OperatorOutlet
    } = useMemo(() => {
        if (!workflow || !selectedOperatorId) return undefined

        if (selectedOperatorId === 'input') {
            return {
                type: "input",
                params: log.params
            }
        } else {
            return {
                type: "operator",
                operator: workflow.operators[selectedOperatorId],
                outlet: (selectedOperatorId in log.operatorOutlets) ? log.operatorOutlets[selectedOperatorId] : undefined
            }
        }
    }, [log.operatorOutlets, log.params, selectedOperatorId, workflow])

    const failedOperatorIds = useMemo(() => {
        return Object.entries(log.operatorOutlets).filter((entry) => entry[1].type === 'failed').map((entry) => entry[0])
    }, [log.operatorOutlets])


    const operators = useMemo(() => {
        if (!workflow) return null

        const result = JSON.parse(JSON.stringify(workflow.operators))
        result['input'] = {
            _id: "input",
            type: 'input',
            parameter: log.params,
            nextOperatorId: workflow.firstOperatorId
        } as InputOperator

        return result
    }, [log.params, workflow])

    const visitedNodeIds = useMemo(() => {
        return ['input', ...Object.keys(log.operatorOutlets)]
    }, [log.operatorOutlets])

    const operatorDurations = useMemo(() => {
        if (!workflow) return undefined

        const result: { [key: string]: number } = {}
        Object.entries(log.operatorOutlets).forEach(([operatorId, outlet]) => {
            result[operatorId] = moment.utc(outlet.endedAt).diff(moment.utc(outlet.startedAt), 'milliseconds')
        })

        return result
    }, [log.operatorOutlets, workflow])

    const rightPanel = useMemo(() => {
        if (!focusedInfo || !workflow) return undefined

        if (focusedInfo.type === 'input') {
            return (
                <Editor className='grow bg-black'
                        defaultLanguage="json"
                        options={{readOnly: true}}
                        defaultValue={JSON.stringify(focusedInfo.params, null, 2)}
                        theme="dark"
                />
            )
        }

        const operatorForm = <OperatorForm workflow={workflow}
                                           readonly={true}
                                           operator={(focusedInfo.operator as Operator)}/>
        if (focusedInfo.outlet) {
            return (
                <Tabs defaultValue="log" className="flex flex-col py-2 px-3 h-full overflow-hidden">
                    <TabsList className="grid w-full grid-cols-2 shrink-0 grow-0 basis-[20px]">
                        <TabsTrigger value="log">Log</TabsTrigger>
                        <TabsTrigger value="operator">Operator</TabsTrigger>
                    </TabsList>
                    <TabsContent value="log" className='grow overflow-y-auto'>
                        {focusedInfo.outlet &&
                            <OperatorOutletDetail operatorOutlet={focusedInfo.outlet}
                                                  operator={focusedInfo.operator}/>
                        }

                    </TabsContent>
                    <TabsContent value="operator" className='grow overflow-y-auto'>
                        {operatorForm}
                    </TabsContent>
                </Tabs>
            )
        } else {
            return operatorForm
        }
    }, [focusedInfo, workflow])

    if (!workflow || !operators) return null

    return (
        <TooltipProvider delayDuration={0}>
            <ResizablePanelGroup
                direction="horizontal"
                onLayout={(sizes: number[]) => {
                    // if (!selectedOperatorOutlet) return

                    localStorage.setItem("react-resizable-panels:layout:mail", JSON.stringify(
                        sizes
                    ))
                }}
                className={classNames("h-full items-stretch flex flex-col", className)}
            >
                <ResizablePanel defaultSize={defaultLayout[0]} minSize={30}>
                    <WorkflowGraph operators={operators}
                                   editable={false}
                                   visitedNodeIds={visitedNodeIds}
                                   firstNodeId={workflow.firstOperatorId}
                                   failedNodeIds={failedOperatorIds}
                                   focusedNodeId={selectedOperatorId}
                                   onClickNode={(e, node) => {
                                       setSelectedOperatorId(node.id)
                                   }}
                                   operatorDurations={operatorDurations}
                                   showDuration={true}
                    />
                </ResizablePanel>

                {rightPanel && focusedInfo &&
                    <>
                        <ResizableHandle withHandle/>
                        <ResizablePanel defaultSize={defaultLayout[1]} minSize={30} collapsible={true}
                                        className='h-full flex flex-col p-3'>

                            <div className="gap-4 text-sm mb-4 px-2">
                                <div className="grid gap-1">
                                    <div className='flex items-center'>
                                        <OperatorIcon
                                            type={focusedInfo.type === 'input' ? 'input' : focusedInfo.operator.type}
                                            size={30}/>
                                        <h3
                                            className="font-semibold grow ml-2">{focusedInfo.type === 'input' ? 'input' : focusedInfo.operator.type}</h3>

                                        {focusedInfo.type === 'operator' && focusedInfo.outlet &&
                                            <span className='text-xs'>
                                            {moment.utc(focusedInfo.outlet.endedAt).diff(moment.utc(focusedInfo.outlet.startedAt), 'milliseconds').toLocaleString("ko-KR")}ms
                                        </span>
                                        }


                                    </div>

                                </div>
                            </div>
                            {rightPanel}

                        </ResizablePanel>
                    </>
                }

            </ResizablePanelGroup>
        </TooltipProvider>
    )
}

export default WorkflowLogViewer