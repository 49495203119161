import React from "react";
import WorkflowJobList from "@/view/workflow/components/WorkflowJobList";

export const WorkflowJobListView = () => {

    return (
        <div className='p-2'>
            <WorkflowJobList/>
        </div>
    )

}

export default WorkflowJobListView;