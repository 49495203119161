import React, {useMemo} from "react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "@/components/ui/table";
import {Sheet, SheetTrigger} from "@/components/ui/sheet";
import {formatIsoReadable} from "@/utils/dateUtils";
import VisibilitySensor from "@/components/ui/sensor";
import useChatbots from "@/query/chatbot/useChatbots";
import {Skeleton} from "@/components/ui/skeleton";
import {useNavigate} from "react-router-dom";
import {ChatBulkTestDialog} from "@/components/chatBulkTestDialog";
import {Button} from "@/components/ui/button";

const ChatbotList: React.FC<{ clusterId?: string }> = ({clusterId}) => {

    const navigate = useNavigate();
    const {
        data,
        hasNextPage,
        fetchNextPage,
        isLoading
    } = useChatbots({clusterId})

    const onScrollAtEnd = async () => {
        if (hasNextPage && !isLoading) {
            await fetchNextPage();
        }
    }

    const chatbots = useMemo(() => {
        return data?.pages.map((page) => page.content).flat()
    }, [data])

    const onClickRow = (chatbotId: string) => {
        navigate(`/chatbots/${chatbotId}`)
    }

    return (
        <div>
            <Table className={'table-auto'}>
                {/*<TableCaption>A list of your recent invoices.</TableCaption>*/}
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[20px] text-center"></TableHead>
                        <TableHead className="w-[100px] text-center">Name</TableHead>
                        {/*<TableHead className='text-center'>Labels</TableHead>*/}
                        <TableHead className='text-right'>Created At</TableHead>
                        <TableHead className='text-right w-[1%] whitespace-nowrap'/>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {data === undefined && Array(10).fill(undefined).map((v, index) => (
                        <TableRow key={`skeleton-row-${index}`} role={'button'} className='border-0'>
                            <TableCell className="font-medium text-right" colSpan={5}>
                                <Skeleton className="h-4 "/>
                                <Skeleton className="h-4 "/>
                            </TableCell>
                        </TableRow>
                    ))}
                    {chatbots?.map((chatbot, index) => (
                        <Sheet key={`log-${chatbot._id}`}>
                            <SheetTrigger asChild>
                                <TableRow key={chatbot._id} className='border-0'>
                                    <TableCell className="font-medium text-center">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell className="font-medium text-center hover:underline hover:cursor-pointer"
                                               onClick={() => onClickRow(chatbot._id)}
                                    >
                                        {chatbot.name}
                                    </TableCell>
                                    {/*<TableCell className="font-medium">{job.channelName}</TableCell>*/}
                                    {/*<TableCell className="font-medium text-center">*/}
                                    {/*    {chatbot.labels?.join(',')}*/}
                                    {/*</TableCell>*/}
                                    <TableCell className="font-medium text-right">
                                        {formatIsoReadable(chatbot.createdAt)}
                                    </TableCell>
                                    <TableCell className="font-medium text-right flex items-center space-x-1 w-[1%] whitespace-nowrap">
                                        <Button variant="outline" onClick={() => navigate(`/chatbots/${chatbot._id}/chat`)}>Go Chat</Button>
                                        <ChatBulkTestDialog chatbot={chatbot}/>
                                    </TableCell>
                                </TableRow>
                            </SheetTrigger>
                            {/*<LogSheetContent log={log}/>*/}
                        </Sheet>
                    ))}

                    {!isLoading &&
                        <tr>
                            <td>
                                <VisibilitySensor onEnter={onScrollAtEnd}/>
                            </td>
                        </tr>
                    }

                </TableBody>

                {/*<TableFooter>*/}
                {/*    <TableRow>*/}
                {/*        <TableCell colSpan={3}>Total</TableCell>*/}
                {/*        <TableCell className="text-right">$2,500.00</TableCell>*/}
                {/*    </TableRow>*/}
                {/*</TableFooter>*/}
            </Table>

        </div>
    )

}

export default ChatbotList