import * as React from "react"
import _ from "lodash";
import {InputOperator, Workflow,} from "@/model/Workflow";
import {OperatorDetailProps} from "@/view/workflow/components/operatorForm/index";
import Editor from "@monaco-editor/react";

interface InputOperatorFormProps extends OperatorDetailProps {
    workflow: Workflow
    operator: InputOperator
}

const InputOperatorForm: React.FC<InputOperatorFormProps> = ({workflow, operator}) => {
    //
    // const _onChangeName = _.debounce(() => {
    //     if (!nameInputRef.current) return
    //
    //     operator.name = nameInputRef.current.value
    //     // onChange({...operator, code: nameInputRef.current.value})
    // }, 300);
    //
    const _onChangeParameter = _.debounce((value?: string) => {
        if (!value) return
        try {
            workflow.defaultParameter = JSON.parse(value)
        } catch {
            return
        }
        workflow.defaultParameter = JSON.parse(value)
    }, 300);

    return (
        <Editor className='bg-black'
                defaultLanguage="json"
                onChange={_onChangeParameter}
                defaultValue={JSON.stringify(operator.parameter, null, 2)}
                theme="dark"
        />
    )
}

export default InputOperatorForm
