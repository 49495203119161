import {Node, NodeProps} from "@xyflow/react";
import {BaseNode} from "@/view/workflow/components/workflowGraph/BaseNode";


function LabeledGroupNode({ data, selected }: NodeProps<Node<{
    label: string;
}>>) {
    return (
        <BaseNode
            selected={selected}
            className="bg-white bg-opacity-50 h-full rounded-sm overflow-hidden p-0"
        >
            {data.label && (
                <div className="bg-gray-200 w-fit p-2 text-xs rounded-br-sm bg-secondary text-card-foreground" >
                    {data.label}
                </div>
            )}
        </BaseNode>
    );
}

LabeledGroupNode.displayName = "LabeledGroupNode";

export default  LabeledGroupNode