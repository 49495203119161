import {GPTPrompt, LLMVendorType} from "@/model/llmSpecification";



export enum OperatorTypeEnum {
    BRANCH = 'branch',
    BRANCH_PYTHON = 'branch_python',
    SEARCH_DATA_SOURCE_RAG = 'search_data_source_rag',
    SEARCH_DATA_SOURCE_FAQ = 'search_data_source_faq',
    SEARCH_DATA_SOURCE_PAGE = 'search_data_source_page',
    LLM = 'llm',
    PYTHON = 'python'
}

export type OperatorType = `${OperatorTypeEnum}`


export interface OperatorBase {
    _id: string
    type: OperatorType
    name?: string

}

export interface BranchOperator extends OperatorBase{
    type: OperatorTypeEnum.BRANCH
    branchMap: {[key: string]: Operator}
}

export interface LLMModel {
    vendor: LLMVendorType
    [key: string]: any
}

// export interface Prompt {
//     role: string
//     content: string
// }

export interface LLMOperator extends OperatorBase{
    type: OperatorTypeEnum.LLM
    model: LLMModel
    stream: boolean
    prompts: GPTPrompt[]
    truncatedOnTokenLimit: object[]
    functionFormat: object
    nextOperatorId?: string
}

export interface SearchRAGOperator extends OperatorBase{
    type: OperatorTypeEnum.SEARCH_DATA_SOURCE_RAG
    mrcAnswerThreshold: number
    mrcThreshold: number
    faqThreshold: number
    size: number
    nextOperatorId?: string
}

export interface BranchPythonOperator extends OperatorBase{
    type: OperatorTypeEnum.BRANCH_PYTHON
    branchMap: {[key: string]: string}
    code: string
    nextOperatorId?: string
}

export interface SearchFAQOperator extends OperatorBase{
    type: OperatorTypeEnum.SEARCH_DATA_SOURCE_FAQ
    threshold: number
    size: number
    nextOperatorId?: string
}

export interface PythonOperator extends OperatorBase{
    type: OperatorTypeEnum.PYTHON
    code: string
    nextOperatorId?: string
}

export type Operator = BranchOperator | BranchPythonOperator | SearchRAGOperator | SearchFAQOperator | LLMOperator | PythonOperator

export interface InputOperator {
    _id: "input"
    type: 'input'
    parameter: object
    nextOperatorId?: string
}

// class BranchOperator(Operator):
// type: Literal[OperatorType.BRANCH] = Field(default=OperatorType.LLM)
// branch_map: Dict[str, List[Operator]]



export interface Workflow {
    _id: string
    description?: string
    firstOperatorId: string
    name?: string
    labels?: string[]
    operators: {[key: string]: Operator}
    defaultParameter?: object
    timeout_sec?: number
    createdAt: string
}
