import axios from "axios";
import {API_HOST} from "@/core/variables";
import {WorkflowHandler} from "@/model/Chatbot";

class ChatbotRepository {

    async read(chatbotId: string) {
        return axios.get(`${API_HOST}/v1/chatbots/${chatbotId}`)
    };

    async paginate(props: {clusterId?: string, page: number, size: number}) {
        return axios.get(`${API_HOST}/v1/chatbots`, {params: props})
    };

    async update(chatbotId: string, props: ChatbotUpdate) {
        return axios.put(`${API_HOST}/v1/chatbots/${chatbotId}`, props)
    };

    async chat(chatbotId: string, query: string, sessionId?: string) {
        return axios.post(`${API_HOST}/v1/chatbots/${chatbotId}/chat`, {
            query, sessionId
        })
    };
}

export interface ChatbotUpdate {
    name?: string;
    description?: string;
    chatHandler?: WorkflowHandler
    welcomeHandler?: WorkflowHandler
}

const repository = new ChatbotRepository();
export default repository
