import ELK from "elkjs/lib/elk.bundled";
import {
    Background,
    Controls,
    Edge,
    Node,
    Panel,
    ReactFlow,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
    useReactFlow
} from "@xyflow/react";
import React, {type MouseEvent as ReactMouseEvent, useCallback, useEffect, useLayoutEffect, useState} from "react";
import LabeledGroupNode from "@/view/workflow/components/workflowGraph/LabeledGroupNode";
import OperatorNode from "@/view/workflow/components/workflowGraph/OperatorNode";
import {Button} from "@/components/ui/button";
import {SAVE_WORKFLOW_MODAL_ID} from "@/view/workflow/components/WorkflowSaveDialog";
import useModal from "@/hook/useModal";
import {Operator} from "@/model/Workflow";

const elk = new ELK();
// Elk has a *huge* amount of options to configure. To see everything you can
// tweak check out:
//
// - https://www.eclipse.org/elk/reference/algorithms.html
// - https://www.eclipse.org/elk/reference/options.html
const elkOptions: any = {
    'elk.algorithm': 'layered',
    'elk.layered.spacing.nodeNodeBetweenLayers': '100',
    'elk.spacing.nodeNode': '80',
    // "elk.nodeSize.constraints": "NODE_LABELS",
    // "elk.nodeLabels.placement": "OUTSIDE"
    // "elk.padding": "[left=20, top=56, right=20, bottom=28]",
    // "elk.contentAlignment": "H_CENTER",
    // "elk.nodeSize.minimum": "(340, 52)",
    // "elk.nodeSize.constraints": "MINIMUM_SIZE"
};

const nodeTypes = {
    labeledGroupNode: LabeledGroupNode,
    operatorNode: OperatorNode,
};


const getLayoutedElements = (nodes: Node[], edges: Edge[], options: any = {}) => {
    const isHorizontal = options?.['elk.direction'] === 'RIGHT';
    const graph: any = {
        id: 'root',
        layoutOptions: options,
        logging: true,
        children: nodes.map((node) => {
            return {
                ...node,
                // Adjust the target and source handle positions based on the layout
                // direction.
                targetPosition: isHorizontal ? 'left' : 'top',
                sourcePosition: isHorizontal ? 'right' : 'bottom',

                // Hardcode a width and height for elk to use when layouting.
                width: node.width ?? 300,
                height: node.height ?? 50,
            }
        }),
        edges: edges,
    };
    return elk
        .layout(graph)
        .then((layoutedGraph) => {
            return {
                nodes: layoutedGraph.children?.map((node) => ({
                    ...node,
                    // React Flow expects a position property on the node instead of `x`
                    // and `y` fields.
                    position: {x: node.x, y: node.y},
                })),

                edges: layoutedGraph.edges,
            }
        })
        .catch(console.error);
};

export interface EditorProps {
    className?: string
    nodes: Node[]
    edges: Edge[]
    editable: boolean
    onClickNode?: (event: ReactMouseEvent, node: Node) => void
    focusedNodeId?: string
}

function LayoutFlow(props: EditorProps) {
    const modal = useModal()
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const {fitView} = useReactFlow();

    const onClickSave = () => {
        modal.open(SAVE_WORKFLOW_MODAL_ID)
    }
    // const onConnect = useCallback(
    //     // @ts-ignore
    //     (params: any) => setEdges((eds) => addEdge(params, eds)),
    //     [],
    // );

    const onLayout = useCallback(
        // @ts-ignore
        ({direction, useInitialNodes = false, autoFitView = false}) => {
            const opts = {'elk.direction': direction, ...elkOptions};
            const ns = useInitialNodes ? props.nodes : nodes;
            const es = useInitialNodes ? props.edges : edges;

            getLayoutedElements(ns, es, opts).then(
                // @ts-ignore
                ({nodes: layoutedNodes, edges: layoutedEdges}) => {
                    setNodes(layoutedNodes);
                    setEdges(layoutedEdges);
                    // if (autoFitView) {
                    //     window.requestAnimationFrame(() => fitView());
                    //     // if (props.defaultFocusNodeId) {
                    //     //     console.log(props.defaultFocusNodeId)
                    //     //
                    //     //     window.requestAnimationFrame(() => fitView({ nodes: [{ id: props.defaultFocusNodeId! }]}));
                    //     // } else {
                    //     //     window.requestAnimationFrame(() => fitView());
                    //     // }
                    //
                    // }
                },
            );
        },
        // eslint-disable-next-line
        [props.nodes, props.edges, setNodes, setEdges, fitView],
    );

    // Calculate the initial layout on mount.
    useLayoutEffect(() => {
        onLayout({direction: 'DOWN', useInitialNodes: true, autoFitView: true});
    }, [onLayout]);

    return (
            <ReactFlow
                nodes={nodes}
                edges={edges}
                // onConnect={onConnect}
                onNodesChange={onNodesChange}
                onNodeClick={props.onClickNode}
                onEdgesChange={onEdgesChange}
                fitView
                nodeTypes={nodeTypes}
                style={{backgroundColor: "#F7F9FB"}}
            >
                {/*<Panel position="top-right">*/}
                {/*    <Button size='sm' variant='outline'*/}
                {/*            onClick={() => onLayout({direction: 'DOWN', autoFitView: true})}>*/}
                {/*        vertical layout*/}
                {/*    </Button>*/}

                {/*    <Button size='sm' variant='outline'*/}
                {/*            onClick={() => onLayout({direction: 'RIGHT', autoFitView: true})}>*/}
                {/*        horizontal layout*/}
                {/*    </Button>*/}
                {/*</Panel>*/}

                {props.editable &&
                    <Panel position="top-left">
                        <Button variant={'outline'} size={'sm'} onClick={onClickSave}>Deploy</Button>
                    </Panel>
                }

                <Background/>
                <Controls
                    fitViewOptions={{nodes: nodes}}
                />
            </ReactFlow>
    );
}

const position = {x: 0, y: 0};

interface WorkflowGraphProps {
    operators: {[key: string]: Operator}
    operatorDurations?: {[key: string]: number}
    onClickNode?: (event: ReactMouseEvent, node: Node) => void
    firstNodeId: string
    editable: boolean
    visitedNodeIds?: string[]
    visitedColor?: string
    failedNodeIds?: string[]
    failedColor?: string
    focusedNodeId?: string
    showDuration?: boolean
}

const WorkflowGraph: React.FC<WorkflowGraphProps> = (props) => {
    const {firstNodeId,
        operators,
        editable,
        visitedNodeIds,
        onClickNode,
        visitedColor = 'blue-500',
        failedNodeIds,
        failedColor = 'red-500',
        focusedNodeId,
        operatorDurations,
        showDuration} = props;

    const convertOperator = useCallback((operator: Operator): Node => {
        let borderColor
        if (failedNodeIds?.includes(operator._id)) {
            borderColor = failedColor
        } else if (visitedNodeIds?.includes(operator._id)) {
            borderColor = visitedColor
        }

        return {
            id: operator._id,
            data: {
                label: operator.type,
                title: operator.name ?? operator.type,
                editable,
                borderColor: borderColor,
                borderWidth: focusedNodeId === operator._id ? 4 : 2,
                duration: operatorDurations && operator._id in operatorDurations ? operatorDurations[operator._id] : undefined,
                showDuration
            },
            position,
            type: "operatorNode",

        }
    }, [failedNodeIds, visitedNodeIds, editable, focusedNodeId, operatorDurations, showDuration, failedColor, visitedColor])

    const [graphData, setGraphData] = useState<{nodes: Node[], edges: Edge[]} | undefined>(undefined)

    useEffect(() => {

        const nodes: Node[] = []
        const edges: Edge[] = []

        Object.values(operators).forEach((operator) => {

            nodes.push(convertOperator(operator))

            if (operator.type === 'branch_python') {
                Object.entries(operator.branchMap).forEach((entry) => {
                    const branchName = entry[0]
                    const operatorId = entry[1]
                    edges.push({
                        id: `edge-${operator._id}-${operatorId}`,
                        source: operator._id,
                        target: operatorId,
                        type: 'default',
                        label: branchName,
                        style: (visitedNodeIds && visitedNodeIds.includes(operatorId)) ? {
                            strokeWidth: 2,
                            stroke: '#3b82f6',
                        } : undefined

                    })
                })
            } else if ("nextOperatorId" in operator && operator.nextOperatorId) {
                edges.push({
                    id: `edge-${operator._id}-${operator.nextOperatorId}`,
                    source: operator._id,
                    target: operator.nextOperatorId,
                    type: 'default',
                    style: (visitedNodeIds && visitedNodeIds.includes(operator._id)) ? {
                        strokeWidth: 2,
                        stroke: '#3b82f6',
                    } : undefined

                })
            }

        })
        setGraphData({nodes, edges})


    }, [visitedColor, visitedNodeIds, convertOperator, editable, firstNodeId, operators])

    if (!graphData) return null

    return (
        <div className="w-full h-full grow overflow-hidden">
            <ReactFlowProvider>
                <LayoutFlow nodes={graphData.nodes}
                            onClickNode={onClickNode}
                            editable={editable}
                            edges={graphData.edges}
                            focusedNodeId={focusedNodeId}

                />
            </ReactFlowProvider>
        </div>
    )
}

export default WorkflowGraph
