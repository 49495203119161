import * as React from "react"
import {Input} from "@/components/ui/input"
import {Label} from "@/components/ui/label"
import {SearchFAQOperator,} from "@/model/Workflow";
import {OperatorDetailProps} from "@/view/workflow/components/operatorForm/index";
import _ from "lodash";

interface SearchFAQOperatorFormProps extends OperatorDetailProps {
    operator: SearchFAQOperator
}

const SearchFAQOperatorForm: React.FC<SearchFAQOperatorFormProps> = ({operator, readOnly = false}) => {

    const _onChangeName = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {

        operator.name = e.target.value
        // onChange({...operator, code: nameInputRef.current.value})
    }, 300);

    const _onChangeSize = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {

        operator.size = e.target.value ? Number(e.target.value) : 0
        // onChange({...operator, code: nameInputRef.current.value})
    }, 300);

    const _onChangeMRCAnswerThreshold = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {

        operator.threshold = e.target.value ? Number(e.target.value) : 0
        // onChange({...operator, code: nameInputRef.current.value})
    }, 300);


    return (
        <div className="h-full flex flex-col space-y-4">
            <div className="flex flex-col space-y-1.5">
                <Label htmlFor="name">Name</Label>
                <Input id="name"
                       placeholder="Name of your project"
                       defaultValue={operator.name}
                       disabled={readOnly}
                       onChange={_onChangeName}
                />
            </div>

            <Label htmlFor="name">Config</Label>
            <div
                className='rounded-xl border bg-card text-card-foreground shadow w-full overflow-hidden p-3 flex flex-col space-y-4'>
                <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="name">Size</Label>
                    <Input id="passageSize"
                           type={'number'}
                           placeholder="Name of your project"
                           defaultValue={operator.size}
                           disabled={readOnly}
                           onChange={_onChangeSize}
                    />
                </div>
                <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="name">Threshold</Label>
                    <Input id="mrcAnswerThreshold"
                           placeholder="Name of your project"
                           type={'number'}
                           defaultValue={operator.threshold}
                           disabled={readOnly}
                           onChange={_onChangeMRCAnswerThreshold}
                    />
                </div>

            </div>

        </div>
    )
}

export default SearchFAQOperatorForm
