import {useQuery, UseQueryOptions} from "@tanstack/react-query";
import {SiteBunnyAdminError, toSiteBunnyAdminError} from "../../error";

import adminSettingRepository from "../../respsitory/AdminSettingRepository";
import {AdminSetting} from "@/model/globalVariable/AdminSetting";

const getData = async () => {
    try {
        const {data} = await adminSettingRepository.read();
        return data.result;
    } catch (e) {
        throw toSiteBunnyAdminError(e);
    }
}



const DEFAULT_QUERY_ARGUMENTS = {retry: false, useErrorBoundary: false, suspense: false}
const useDefaultAdminSetting = (options?: Omit<UseQueryOptions<AdminSetting, SiteBunnyAdminError>, 'queryKey'>) => {
    const argument =  options ? {...DEFAULT_QUERY_ARGUMENTS, ...options} : DEFAULT_QUERY_ARGUMENTS
    return useQuery<AdminSetting, SiteBunnyAdminError>({
        queryKey: ['adminSetting'],
        queryFn: async () => getData(),
        ...argument
    })
}

export default useDefaultAdminSetting;
