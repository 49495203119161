import {useMutation} from "@tanstack/react-query";
import {AxiosError} from "axios";
import workflowRepository from "../../respsitory/workflowRepository";
import {Workflow} from "@/model/Workflow";


const useCloneWorkflow = (workflowId: string) => {
    return useMutation<Workflow, AxiosError, string>({
        mutationKey: ["workflow", 'clone', workflowId],
        mutationFn: async (name: string) => {
            try {
                const {data} = await workflowRepository.clone(workflowId, name);
                return data.result;
            } catch (e) {
                throw e;
            }
        }
    })
};
export default useCloneWorkflow