import React from "react";
import {Outlet, useParams} from "react-router-dom";
import useChannel from "@/query/channel/useChannel";

const WorkspaceWrapper  = () => {
    const { channelId } = useParams<{ channelId: string}>();
    const {data: workspace} = useChannel(channelId)

    if (workspace) {
        return <Outlet/>
    } else {
        return null
    }

}

export default WorkspaceWrapper