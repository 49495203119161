import * as React from "react"
import {useRef} from "react"
import {Input} from "@/components/ui/input"
import {Label} from "@/components/ui/label"
import {BranchPythonOperator,} from "@/model/Workflow";
import Editor from '@monaco-editor/react';
import {OperatorDetailProps} from "@/view/workflow/components/operatorForm/index";
import _ from "lodash";

interface PythonOperatorFormProps extends OperatorDetailProps {
    operator: BranchPythonOperator
}
const PythonBranchOperatorForm: React.FC<PythonOperatorFormProps> = ({operator, readOnly, onChange}) => {
    const nameInputRef = useRef<HTMLInputElement>(null);

    const _onChangeName = _.debounce(() => {
        if (!nameInputRef.current) return

        operator.name = nameInputRef.current.value
        // onChange({...operator, code: nameInputRef.current.value})
    }, 300);

    const _onChangeCode = _.debounce((value?: string) => {
        operator.code = value ?? ""
        // onChange({...operator, code: value ?? ""})
    }, 300);

    return (
        <div className="h-full flex flex-col space-y-3 ">
            <div className="flex flex-col space-y-1.5">
                <Label htmlFor="name">Name</Label>
                <Input id="name"
                       ref={nameInputRef}
                       placeholder="Name of your project"
                       disabled={readOnly}
                       defaultValue={operator.name}
                       onChange={_onChangeName}
                />
            </div>
            <div className="flex flex-col space-y-1.5 grow">
                <Label htmlFor="framework">Code</Label>

                <Editor className='grow bg-black'
                        defaultLanguage="python"
                        defaultValue={operator.code}
                        theme="dark"
                        options={{readOnly: readOnly}}
                        onChange={_onChangeCode}
                />
            </div>
        </div>
    )
}

export default PythonBranchOperatorForm
