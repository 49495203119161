export enum GPTPromptRoleType {
    SYSTEM = 'system',
    ASSISTANT = 'assistant',
    USER = 'user'
}

export interface GPTPrompt {
    role: GPTPromptRoleType
    content: string
}


export enum LLMVendorType {
    OPENAI = "openai",
    LLM42 = "llm42",
    AZURE_OPENAI = "azure-openai",
    NAVER = "naver",
    META = "meta",
    ANTHROPIC = 'anthropic',
}

export const OPENAI_SUPPORTED_MODELS = [
    "gpt-3.5-turbo",
    "gpt-3.5-turbo-16k",
    "gpt-4",
    "gpt-4-32k"
]

export const LLM42_SUPPORTED_MODELS = [
    "LLM42",
    "LLM42_qa",
    "LLM42_qc"
]

export const AZURE_OPENAI_SUPPORTED_MODELS = [
    "gpt-35-turbo-0125",
    "gpt-4o"
]

export const NAVER_SUPPORTED_MODELS = [
    "HCX-002",
]

export const META_SUPPORTED_MODELS = [
    "LLAMA-3.2",
]

export const ANTHROPIC_SUPPORTED_MODELS = [
    "claude-3-5-sonnet-20240620",
]

export const MODEL_MAX_OUTPUT_TOKENS = {
    "gpt-3.5-turbo": 4096,
    "gpt-4": 8192,
    "LLM42": 4000,
    "LLM42_qa": 4000,
    "LLM42_qc": 4000,
    "HCX-002": 4000,
    "gpt-35-turbo-0125": 4096,
    "gpt-4o": 16384,
    "LLAMA-3.2": 4096,
    "claude-3-5-sonnet-20240620": 8192
}


export interface LLMSpecificationBase {
    key: string;
    description?: string;
    dependency: boolean;
    vendor: LLMVendorType;
    model: string;
    temperature: number;
    maxTokens: number;
}

export interface LLMSpecification extends LLMSpecificationBase {
    _id: string;
    createdAt: string;
    updatedAt: string;
    contextWindowSize: number;
}

export interface LLMSpecificationHistoryBase {
    llmSpecification: LLMSpecification;
    version: number
}

export interface LLMSpecificationHistory extends LLMSpecificationHistoryBase {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export type LLMSpecificationCreateRequest = Omit<LLMSpecificationBase, "dependency" | "contextWindowSize">
export type LLMSpecificationUpdateRequest = Omit<LLMSpecificationBase, "dependency" | "contextWindowSize">
