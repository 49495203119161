import chatbotRepository from "../../respsitory/ChatbotRepository";
import {useInfiniteQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Page} from "../index";
import {Chatbot} from "@/model/Chatbot";


const getData = async (props: {clusterId?: string, page: number, size: number}) => {
    try {
        const {data} = await chatbotRepository.paginate({clusterId: props.clusterId, page: props.page, size: props.size})
        return {
            content: data.result.content,
            pageable: {
                skip: props.page,
                limit: props.size,
                isLast: data.result.isLast
            }
        }
    } catch (e) {
        throw e;
    }
}

const LIMIT = 20
const useChatbots = (props: {clusterId?: string}) => {
    return useInfiniteQuery<Page<Chatbot>, AxiosError>({
        queryKey: ["chatbots", props.clusterId],
        queryFn: async ({ pageParam = { skip: 1, limit: LIMIT }}) => getData(
            {clusterId: props.clusterId, page: pageParam.skip, size: pageParam.limit}
        ),
        refetchOnMount: true,
        useErrorBoundary: false,
        getNextPageParam: ({pageable}) => {
            if (pageable.isLast) {
                return undefined
            }

            return {
                skip: pageable.skip + 1,
                limit: pageable.limit,
            }
        }
    })
}

export default useChatbots;
