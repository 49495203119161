import React from "react";
import ChatbotList from "@/view/chatbot/components/ChatbotList";


export const ChatbotListView = () => {


    return (
        <div className='p-2'>
            <ChatbotList/>
        </div>
    )

}


export default ChatbotListView;