"use client";
import React from "react";
import python from "../../../assets/svgs/python.svg";
import faq from "../../../assets/svgs/faqs.svg";
import ai from "../../../assets/svgs/ai.svg";
import {FileSearch2, Split, FileInput} from "lucide-react";
import {OperatorTypeEnum} from "@/model/Workflow";

const OperatorIcon: React.FC<{type: string, size?: number}> = ({type, size = 24})  => {

    if (type === OperatorTypeEnum.PYTHON) {
        return <img src={python} alt="python-operator" width={size}/>
    } else if (type === OperatorTypeEnum.BRANCH_PYTHON) {
        return <Split size={size}/>
    } else if (type === OperatorTypeEnum.SEARCH_DATA_SOURCE_RAG) {
        return <FileSearch2 size={size}/>
    } else if (type === OperatorTypeEnum.SEARCH_DATA_SOURCE_FAQ) {
        return <img src={faq} alt="faq-operator" width={size}/>

    } else if (type === OperatorTypeEnum.LLM) {
        return <img src={ai} alt="LLM" width={size}/>
    } else if (type === 'input') {
        return <FileInput size={size}/>
    } else {
        return null
    }
}

export default OperatorIcon