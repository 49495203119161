import React, {useRef, useState} from "react";
import {useParams} from "react-router-dom";
import useWorkflow from "@/query/workflow/useWorkflow";
import WorkflowLogList, {WorkflowLogListRef} from "@/view/workflowLog/workflowLogList";
import {Workflow} from "@/model/Workflow";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@/components/ui/card";
import {Label} from "@/components/ui/label";
import {Button} from "@/components/ui/button";
import Editor, {Monaco} from "@monaco-editor/react";
import toast from "react-hot-toast";
import workflowRepository from "@/respsitory/workflowRepository";
import useProfile from "@/query/auth/useProfile";

const WorkflowRunView = () => {
    const {workflowId} = useParams<{ channelId: string, workflowId: string }>();
    const {data: workflow} = useWorkflow(workflowId!)

    const workflowResultListRef = useRef<WorkflowLogListRef>(null)
    const onSubmit = () => {
        if (!workflowResultListRef.current) return

        workflowResultListRef.current.invalidate()
    }
    if (!workflow) return null
    return (
        <div className='flex h-full'>
            <div className='h-full basis-[30%]'>
                <WorkflowLogList workflowIds={workflowId ? [workflowId] : undefined} showDownload={false} ref={workflowResultListRef}/>
            </div>
            <div className='p-3 grow h-full'>
                <Trigger workflow={workflow} onSubmit={onSubmit}/>
            </div>

        </div>
    )
}

const Trigger: React.FC<{ workflow: Workflow, onSubmit: () => void }> = ({workflow, onSubmit}) => {
    const [pending, setPending] = useState(false)
    const editorRef = useRef<any>(null);

    function handleEditorDidMount(editor: any, monaco: Monaco) {
        editorRef.current = editor;
    }

    const {data: user} = useProfile({useErrorBoundary: false});
    const trigger = async () => {
        if(!user || !editorRef.current) return

        let parameter: object | null

        try {
            parameter = JSON.parse(editorRef.current.getValue());
        } catch (e) {
            toast.error("JSON 형식이 아닙니다.")
            return
        }

        const loadingToastId = toast.loading("Running...")
        setPending(true)
        try {
            await workflowRepository.run(user.workflowUserId, workflow._id, parameter!)
            // data.result {
            //     "_id": "679c85a04cf7a293dbaac3dc",
            //     "result": {
            //         "answer": "서강희는 2024년에 결혼했습니다."
            //     },
            //     "sessionId": "679c85a34cf7a293dbaac3dd",
            //     "reply_flag": true
            // }
            // console.log(result)
            toast.remove(loadingToastId)
            onSubmit()
            // navigate("/channels", {replace: true});
        } catch (e) {
            console.log(e)
            toast.error("error", {id: loadingToastId});
        } finally {
            setPending(false)
        }
    }
    return (
        <Card className="w-full h-full flex flex-col">
            <CardHeader>
                <CardTitle>Run Workflow</CardTitle>
                <CardDescription>Deploy your new project in one-click.</CardDescription>
            </CardHeader>
            <CardContent className='grow'>
                <div className="w-full h-full flex flex-col space-y-1.5">
                        <Label htmlFor="name">Parameters</Label>
                        <Editor className='grow bg-black'
                                defaultLanguage="json"
                                defaultValue={JSON.stringify(workflow.defaultParameter, null, 2)}
                                theme="dark"
                                onMount={handleEditorDidMount}
                        />
                </div>
            </CardContent>
            <CardFooter className="flex justify-start">
                <Button onClick={trigger} disabled={pending}>Trigger</Button>
            </CardFooter>
        </Card>
    )

}

export default WorkflowRunView