import axios from "axios";
import {WOKRFLOW_API_HOST} from "@/core/variables";
import {WorkflowLogStatus} from "@/model/Log";

class WorkflowResultRepository {

    async read(resultId: string) {
        return axios.get(`${WOKRFLOW_API_HOST}/v1/results/${resultId}`)
    };

    async paginate(page: number, size: number, groupId?: string, status?: WorkflowLogStatus, workflowIds?: string) {
        return axios.get(`${WOKRFLOW_API_HOST}/v1/results`, {
            params: {page, size, groupId, status, workflowIds}
        })
    };
}

const jobRepository = new WorkflowResultRepository();
export default jobRepository
