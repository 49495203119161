"use client"

import * as React from "react"
import {useEffect, useMemo, useState} from "react"
import _ from "lodash";
import {Button} from "@/components/ui/button"
import {ClipLoader} from "react-spinners";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@/components/ui/card";
import {Label} from "@/components/ui/label";
import useWorkflows from "@/query/workflow/useWorkflows";
import useDefaultAdminSetting from "@/query/globalVariables/useDefaultAdminSetting";
import workflowRepository from "@/respsitory/workflowRepository";
import Combobox from "@/components/ui/combobox";
import useCreateWorkflow from "@/query/workflow/useCreateWorkflow";
import toast from "react-hot-toast";
import {Workflow} from "@/model/Workflow";

const ChannelCommonSettingView = () => {

    const {data: adminSetting} = useDefaultAdminSetting()

    const [defaultWorkflows, setDefaultWorkflows] = useState<{defaultChatWorkflow: Workflow} | undefined>(undefined)

    useEffect(() => {
        if (!adminSetting) return undefined

        workflowRepository.find([adminSetting.defaultChatWorkflowId]).then(({data}) => {
            const defaultChatWorkflow = data.result.find((workflow: Workflow) => workflow._id === adminSetting.defaultChatWorkflowId)
            setDefaultWorkflows({defaultChatWorkflow: defaultChatWorkflow})
        })

    }, [adminSetting])

    if (defaultWorkflows) {
        return <AdminSettingCard defaultChatWorkflow={defaultWorkflows.defaultChatWorkflow}/>
    } else {
        return (
            <div className='flex items-center justify-center'>
                <ClipLoader color={'grey'}/>
            </div>
        )
    }

}

const AdminSettingCard: React.FC<{ defaultChatWorkflow: Workflow }> = ({defaultChatWorkflow}) => {

    const [workflowName, setWorkflowName] = useState<string>("")
    const {
        data,
    } = useWorkflows({name: workflowName, labels: ['sitebunny-chat']})

    const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow>(defaultChatWorkflow)

    const workflows = useMemo(() => {
        return data?.pages.map((page) => page.content).flat()
    }, [data])

    const {mutateAsync: onSubmit} = useCreateWorkflow(selectedWorkflow._id)

    const onChange = _.debounce((search: string) => {
        setWorkflowName(search);
    }, 300);

    return (
        <Card>
            <CardHeader>
                <CardTitle>관리자 설정</CardTitle>
                <CardDescription>...</CardDescription>
            </CardHeader>
            <CardContent>
                <div className="grid w-full items-center gap-4">
                    {/*<div className="flex flex-col space-y-1.5">*/}
                    {/*    <Label htmlFor="name">Name</Label>*/}
                    {/*    <Input id="name" placeholder="Name of your project" />*/}
                    {/*</div>*/}

                    <Label htmlFor="framework">Default Chat </Label>
                    <Combobox placeholder={selectedWorkflow
                        ? selectedWorkflow.name
                        : "Select Workflow..."}
                              onChangeQuery={onChange}
                              items={workflows ? workflows.map((workflow) => ({label: workflow.name || '', value: workflow._id})) : []}
                              onChangeValue={(selectedWorkflowId) => {

                                  const _selectedWorkflow = workflows?.find((workflow) => workflow._id === selectedWorkflowId)
                                  if (_selectedWorkflow) {
                                      setSelectedWorkflow(_selectedWorkflow)
                                  }
                              }}
                    />

                    <Label className='text-gray-500' htmlFor="framework">Default Create Welcome</Label>
                    <Combobox items={[]} onChangeValue={() => {}} onChangeQuery={() => {}}/>

                    <Label className='text-gray-500' htmlFor="framework">Default Create Suggested Question</Label>
                    <Combobox items={[]} onChangeValue={() => {}} onChangeQuery={() => {}}/>
                </div>

            </CardContent>
            <CardFooter className="flex justify-end">
                <Button onClick={() => {onSubmit().then(() => {
                    toast.success("저장")
                }).catch(() => {
                    toast.error("에러")
                })}}>저장</Button>
            </CardFooter>
        </Card>
    )
}

export default ChannelCommonSettingView;