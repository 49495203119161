import React, {useCallback, useEffect, useRef, useState} from "react";
import ExcelJS from "exceljs";
import {saveAs} from "file-saver";
import logRepository from "@/respsitory/workflowLogRepository";
import {OperatorOutlet, WorkflowLog, WorkflowLogStatus} from "@/model/Log";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@/components/ui/dropdown-menu";

const DownloadButton: React.FC<{ groupId?: string, size: number, status?: WorkflowLogStatus }> = ({groupId, size = 20, status}) => {
    const [page, setPage] = useState<undefined | number>(undefined)
    const workbookRef = useRef<ExcelJS.Workbook | undefined>(undefined)
    const worksheetRef = useRef<ExcelJS.Worksheet | undefined>(undefined)

    const start = () => {
        if (workbookRef.current) return

        workbookRef.current = new ExcelJS.Workbook();
        worksheetRef.current = workbookRef.current.addWorksheet('sheet')
        setPage(1)
    }

    const download = useCallback(async () => {
        if (workbookRef.current === undefined || worksheetRef.current === undefined) return

        const buffer = await workbookRef.current.xlsx.writeBuffer();

        new Blob([buffer])
        saveAs(new Blob([buffer]), 'workflow_logs.xlsx');

        workbookRef.current = undefined
        worksheetRef.current = undefined

    }, [])

    const loadRows = useCallback(async (_page: number) => {
        const {data} = await logRepository.paginate(_page, size, groupId, status)

        const logs: WorkflowLog[] = data.result.content
        return logs.reverse().map((log) => logToRow(log))
    }, [groupId, size, status])

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        if (page !== undefined ) {
            sleep(1000).then(() => {
                if (page === undefined || worksheetRef.current === undefined) return
                return loadRows(page)

            }).then((rows) => {
                if (rows === undefined || page === undefined || worksheetRef.current === undefined) return

                if (page === 1 && rows.length > 0) {
                    worksheetRef.current.columns = Object.keys(rows[0]).map((column) => ({
                        header: column,
                        key: column
                    }))
                }
                worksheetRef.current.addRows(rows)
                if (rows.length < size) {
                    setPage(undefined)
                } else {
                    setPage((prev) => (prev! + 1))
                }
            }).finally()

        } else {
            download().catch()
        }
    }, [size, page, loadRows, download])

    const logToRow = (log: WorkflowLog): { [key: string]: string } => {
        const row = log.params ? {...log.params} : {};
        const operatorOutlets = Object.values(log.operatorOutlets);

        // FAQ
        const operator6 = getOperator("search-faq", operatorOutlets);
        if (operator6) {
            const outlet = operator6.outlet;
            row["FAQ Record Id"] = outlet?.faqId || null
        } else {
            row["FAQ Record Id"] = null
        }

        // Query Analyze
        const operator1 = getOperator("query-analyze", operatorOutlets);
        if (operator1) {
            const outlet = operator1.outlet;
            row["Query Analyze(Prompt)"] = indentValue(operator1.prompts)
            row["Query Analyze"] = indentValue(indentValue(outlet.messages))

        } else {
            row["Query Analyze(Prompt)"] = null
            row["Query Analyze"] = null
        }

        // Document Search
        const operator2 = getOperator("document-search", operatorOutlets);
        if (operator2) {
            const outlet = operator2.outlet;
            const resultType = outlet.type;

            if (resultType === "passages" || resultType === "mrc") {
                const passages = outlet.passages || [];
                for (let i = 0; i < 3; i++) {
                    if (passages[i]) {
                        row[`record_id.${i+1}`] = passages[i].recordId
                        row[`title.${i+1}`] = passages[i].title
                        row[`title_link.${i+1}`] = passages[i].titleLink
                        row[`Result Passage.${i+1}`] = resultType === "passages" ? passages[i].content : passages[i].passage
                    } else {
                        row[`record_id.${i+1}`] = null
                        row[`title.${i+1}`] = null
                        row[`title_link.${i+1}`] = null
                        row[`Result Passage.${i+1}`] = null
                    }
                }
            } else {
                for (let i = 0; i < 3; i++) {
                    row[`record_id.${i+1}`] = null
                    row[`title.${i+1}`] = null
                    row[`title_link.${i+1}`] = null
                    row[`Result Passage.${i+1}`] = null
                }
            }
            row["Document Search Type"] = resultType
        } else {
            for (let i = 0; i < 3; i++) {
                row[`record_id.${i+1}`] = null
                row[`title.${i+1}`] = null
                row[`title_link.${i+1}`] = null
                row[`Result Passage.${i+1}`] = null
            }
            row["Document Search Type"] = null
        }

        // Generate Answer
        const operator3 = getOperator("generate-answer", operatorOutlets);
        if (operator3) {
            const outlet = operator3.outlet;
            row['Generate Answer(Prompt)'] = indentValue(operator3.prompts)
            row['Generate Answer(LLM)'] = indentValue(outlet.messages)
        } else {
            row['Generate Answer(Prompt)'] = null
            row['Generate Answer(LLM)'] = null
        }

        // Refine Answer
        const operator4 = getOperator("refine-answer", operatorOutlets);
        if (operator4) {
            const outlet = operator4.outlet;
            row["Result Answer"] = outlet.answer
            row["Result Reference"] = outlet.reference
            row["Result Suggested Question"] = outlet.question_list
        } else {
            row["Result Answer"] = null
            row["Result Reference"] = null
            row["Result Suggested Question"] = null
        }

        return row
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger>
                Download
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuItem disabled={true}>일반</DropdownMenuItem>
                {groupId && <DropdownMenuItem onClick={start}>기획 질의 포맷</DropdownMenuItem> }
            </DropdownMenuContent>
        </DropdownMenu>

    )
}

function getOperator(name: string, operatorOutlets: OperatorOutlet[]): OperatorOutlet | null {
    return operatorOutlets.find((operatorOutlet) => operatorOutlet.operatorName === name) || null;
}

function indentValue(operatorOutlet: any): string {
    if (typeof operatorOutlet === "string") {
        try {
            return JSON.stringify(JSON.parse(operatorOutlet), null, 4).replace("\\n", "\n");
        } catch (e) {
            return operatorOutlet.replace("\\n", "\n");
        }
    } else {
        return JSON.stringify(operatorOutlet, null, 4).replace("\\n", "\n");
    }
}


export default DownloadButton