import React from "react";
import WorkflowList from "@/view/workflow/components/WorkflowList";


export const WorkflowMain = () => {


    return (
        <div className='p-2'>
            <WorkflowList/>
        </div>
    )

}


export default WorkflowMain;