import axios from "axios";
import {API_HOST} from "@/core/variables";

class AdminSettingRepository {
    async read() {
        return axios.get(`${API_HOST}/v1/admin-settings/default`, )
    };

    async create(defaultChatWorkflowId: string) {
        return axios.post(`${API_HOST}/v1/admin-settings`, {
            defaultChatWorkflowId
        })
    };
}
// eslint-disable-next-line
export default new AdminSettingRepository();
