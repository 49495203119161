import workflowRepository from "../../respsitory/workflowRepository";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Workflow} from "@/model/Workflow";


const getData = async (workflowId: string) => {
    try {
        const {data} = await workflowRepository.read(workflowId)
        return data.result
    } catch (e) {
        throw e;
    }
}

const useWorkflow = (workflowId: string) => {
    return useQuery<Workflow, AxiosError>({
        queryKey: ["workflow", workflowId],
        queryFn: async () => getData(workflowId),
        retry: false,
        suspense: false,
    })
}

export default useWorkflow;
