import React, {useMemo} from "react";
import {OperatorOutlet} from "@/model/Log";
import {cn} from "@/lib/utils";
import {ScrollArea} from "@/components/ui/scroll-area";
import {Badge} from "@/components/ui/badge";
import {Operator} from "@/model/Workflow";

interface OperatorOutletDetailProps {
    operatorOutlet: OperatorOutlet
    operator: Operator
}

export function OperatorOutletDetail({ operatorOutlet }: OperatorOutletDetailProps) {

    const outlet = useMemo(() => {
        if (typeof operatorOutlet.outlet === 'object') {
           return JSON.stringify(operatorOutlet.outlet, null, 2)
        } else {
            return operatorOutlet.outlet
        }
    }, [operatorOutlet.outlet])

    return (
        <ScrollArea className="flex h-full flex-col pt-4">
            <div className="flex flex-1 flex-col">

                <div className='flex flex-col gap-3'>
                    <div
                        className={cn(
                            "flex flex-col outlets-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
                        )}
                    >
                        <div className="font-semibold">Outlet</div>
                        <div className="flex-1 whitespace-pre-wrap text-muted-foreground ">
                            {outlet}
                        </div>
                    </div>
                    {operatorOutlet.prompts?.map((prompt, index) => (
                        <div
                            key={`prompt-${index}`}
                            className={cn(
                                "flex flex-col outlets-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
                            )}
                        >
                            <div className='flex items-center'>
                                <div className="font-semibold">{`Prompt ${index + 1}`}</div>
                                <Badge variant={"outline"} className="ml-2">
                                    {prompt.role}
                                </Badge>
                            </div>

                            <div>
                                <p className="flex-1 whitespace-pre-wrap text-muted-foreground p-1">
                                    {prompt.content}
                                </p>
                            </div>


                        </div>
                    ))}
                </div>
            </div>
        </ScrollArea>
    )
}