import {Button} from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {Input} from "@/components/ui/input"
import {Label} from "@/components/ui/label"
import jobRepository from "../respsitory/workflowJobRepository";
import React, {useMemo, useRef, useState} from "react";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {Textarea} from "@/components/ui/textarea";
import {Chatbot} from "@/model/Chatbot";
import {WorkflowComboBox, WorkflowChatHandlerComboBoxRef} from "@/view/chatbot/ChatHandlerCombobox";
import useProfile from "@/query/auth/useProfile";

export const ChatBulkTestDialog: React.FC<{ chatbot: Chatbot }> = ({chatbot}) => {
    const navigate = useNavigate();
    const fileRef = useRef<HTMLInputElement>(null);
    const chatHandlerRef = useRef<WorkflowChatHandlerComboBoxRef>(null)
    const [file, setFile] = useState<File | null>(null);
    const [mutating, setMutating] = useState<boolean>(false);


    const {data: user} = useProfile();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        setFile(e.target.files[0]);
    };

    // TODO userId 필드 추가필요 [ sitebunny-api workflow 로직변경에 따른 값 변경 ]
    const defaultParams = useMemo(() => ({
        "chatbot_id": chatbot._id,
        "chatbot_name": chatbot.name,
        "language": chatbot.language,
        "faq_cluster_id": chatbot.faqClusterId,
        "rag_cluster_id": chatbot.ragClusterId,
    }), [chatbot])
    const submit = async () => {
        if (!file || mutating || !chatHandlerRef.current || !user) return;

        const chatHandler = chatHandlerRef.current.chatHandler()
        if (!chatHandler) {
            toast.error("Chat Handler 설정이 필요합니다.")
            return
        }

        setMutating(true);
        try {
            await jobRepository.bulkTestWorkflow(
                user.workflowUserId,
                chatHandler._id,
                file,
                defaultParams)
            navigate(`/workflow-service/jobs`)
        } catch (e: any) {
            console.log(e)
            toast.error("실패했습니다.")
        }
        setMutating(false);
    };


    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="outline">Chat Bulk Test</Button>
            </DialogTrigger>
            <DialogContent className="sm:min-w-[625px]">
                <DialogHeader>
                    <DialogTitle>Chat Bulk Test</DialogTitle>
                    <DialogDescription>
                        xlsx 파일을 활용하여 질의 처리를 테스트합니다.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                        <Label htmlFor="picture">Test Set</Label>
                        <Input id="picture" type="file" ref={fileRef} onChange={handleFileChange}/>
                    </div>

                    <div className="grid w-full max-w-sm items-center gap-1.5">
                        <Label htmlFor="version">Chat Workflow</Label>
                        <WorkflowComboBox ref={chatHandlerRef} defaultWorkflowId={chatbot.chatHandler.workflowId}/>
                    </div>

                    <div className="grid w-full items-center gap-1.5">
                        <Label htmlFor="message">Default Params</Label>
                        <Textarea value={JSON.stringify(defaultParams, null, 2)}
                                  rows={5}
                                  readOnly={true}
                                  autoFocus={false}/>
                    </div>


                </div>
                <DialogFooter>
                    <Button type="submit" onClick={submit}>Save</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
