import workflowRepository from "../../respsitory/workflowRepository";
import {useInfiniteQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Page} from "../index";
import {Workflow} from "@/model/Workflow";


const getData = async (props: {page: number, size: number, name?: string, labels?: string[]}) => {
    try {
        const {data} = await workflowRepository.paginate({page: props.page, size: props.size, name: props?.name, labels: props.labels})
        return {
            content: data.result.content,
            pageable: {
                skip: props.page,
                limit: props.size,
                isLast: data.result.isLast
            }
        }
    } catch (e) {
        throw e;
    }
}

const LIMIT = 20
const useWorkflows = (props?: {name?: string, labels?: string[]}) => {
    return useInfiniteQuery<Page<Workflow>, AxiosError>({
        queryKey: ["workflows", props?.name, props?.labels],
        queryFn: async ({ pageParam = { skip: 1, limit: LIMIT }}) => getData(
            {page: pageParam.skip, size: pageParam.limit, labels: props?.labels, name: props?.name}
        ),
        refetchOnMount: true,
        useErrorBoundary: false,
        getNextPageParam: ({pageable}) => {
            if (pageable.isLast) {
                return undefined
            }

            return {
                skip: pageable.skip + 1,
                limit: pageable.limit,
            }
        }
    })
}

export default useWorkflows;
