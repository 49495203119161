import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Chatbot} from "@/model/Chatbot";
import chatbotRepository, {ChatbotUpdate} from "@/respsitory/ChatbotRepository";


const useUpdateChatbot = (chatbotId: string) => {
    const queryClient = useQueryClient();

    return useMutation<Chatbot, AxiosError, ChatbotUpdate>({
        mutationKey: ["chatbot", "update", chatbotId],
        mutationFn: async (body: ChatbotUpdate) => {
            try {
                const { data } = await chatbotRepository.update(chatbotId, body);
                return data.result;
            } catch (e) {
                throw e;
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["chatbot", chatbotId],
                exact: false,
            });
        }
    })
};

export default useUpdateChatbot;
