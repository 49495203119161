import React, {useMemo, useState} from "react";
import classNames from "classnames";
import Datepicker from "react-tailwindcss-datepicker";
import styled from "styled-components";
import {DateValueType, ShortcutsItem} from "react-tailwindcss-datepicker/dist/types";
import moment from "moment";
import {subtractDays} from "@/utils/dateUtils";
// @ts-ignore
import calendarIcon from "../assets/svgs/icon_calendar.svg";

type DateRangePickerProps = {
    period: DateValueType;
    setPeriod: React.Dispatch<React.SetStateAction<DateValueType>>;
    setAnnual?: React.Dispatch<React.SetStateAction<boolean>>;
    className?: string;
    containerClassName?: string;
    inputClassName?: string;
};

const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {
    const { period, setPeriod, setAnnual, className, containerClassName, inputClassName } = props;

    const [selectedShortcutIndex, setSelectedShortcutIndex] = useState<number>(5);

    const today = useMemo(() => new Date(), []);

    const firstDayOfLastMonth = useMemo(() => {
        return new Date(today.getFullYear(), today.getMonth() - 1, 1);
    }, [today])

    const calendarShortcuts = useMemo<{[key: string]: ShortcutsItem}>(() => {
        const getToday = () => {
            return new Date(today);
        }

        return {
            _today: {
                text: "오늘",
                period: {
                    start: getToday(),
                    end: getToday()
                }
            },
            _yesterday: {
                text: "어제",
                period: {
                    start: subtractDays(getToday(), 1),
                    end: getToday()
                }
            },
            lastWeek: {
                text: "지난 주",
                period: {
                    start: subtractDays(getToday(), 7),
                    end: getToday()
                }
            },
            lastMonth: {
                text: "지난 30일",
                period: {
                    start: subtractDays(getToday(), 30),
                    end: getToday()
                }
            },
            last3Month: {
                text: "지난 3달",
                period: {
                    start: subtractDays(getToday(), 84),
                    end: getToday()
                }
            },
            _custom: {
                text: "사용자 지정",
                period: {
                    start: period?.startDate ?? new Date(),
                    end: period?.endDate ?? new Date(),
                }
            }
        }
    }, [today, period])

    const handlePeriodChange = (value: DateValueType) => {
        if (!value?.startDate || !value?.endDate) {
            return;
        }

        let shortcutFound = false;

        Object.keys(calendarShortcuts).forEach((key, index) => {
            if (key === "_custom") {
                return;
            }
            const shortcut = calendarShortcuts[key];
            const shortcutStartDate = moment(shortcut.period.start).format("YYYY-MM-DD");
            const shortcutEndDate = moment(shortcut.period.end).format("YYYY-MM-DD");

            if (shortcutStartDate === value.startDate && shortcutEndDate === value.endDate) {
                shortcutFound = true;
                setSelectedShortcutIndex(index);
            }
        })

        if (!shortcutFound) {
            setSelectedShortcutIndex(5);
        }

        setAnnual?.(moment(value.endDate).diff(moment(value.startDate), "years") >= 1)
        setPeriod(value);
    }

    return (
        <DatepickerStyle selectedShortcutIndex={selectedShortcutIndex}
                         className={classNames(
                             className ?? "flex items-center h-[40px] bg-[#F9F9F9] rounded-[4px] cursor-pointer w-[300px]"
                         )}>
            <img src={calendarIcon} alt="calendar" width={24} height={24} className="ml-[16px]"/>

            <Datepicker value={period}
                        placeholder={"Choose period"}
                        onChange={handlePeriodChange}
                        startFrom={firstDayOfLastMonth}
                        showShortcuts={true}
                        configs={{
                            shortcuts: calendarShortcuts,
                            footer: {
                                cancel: "취소",
                                apply: "적용",
                            }
                        }}
                        displayFormat="YYYY년 MM월 DD일"
                        separator={"-"}
                        readOnly
                        showFooter={true}
                        containerClassName={(className) => classNames(className, containerClassName ?? "datepicker bg-[#F9F9F9] rounded-[4px] border-none text-[14px] cursor-default z-10")}
                        inputId={"datepicker-input"}
                        inputName={"datepicker-input"}
                        inputClassName={inputClassName ?? "relative w-full px-[8px] focus:ring-0 bg-[#F9F9F9] rounded-[4px] text-[14px] text-bunny-label-black font-medium border-none cursor-pointer caret-transparent"}
                        toggleIcon={(open) => <></>}
                        toggleClassName={"hidden"}
            />
        </DatepickerStyle>
    );
};

const DatepickerStyle = styled.div<{selectedShortcutIndex?: number}>`
  .datepicker {
    & > div {
      left: -36px;
      
      & > div:nth-child(1) {
        visibility: hidden;
      }
      
      & > div:nth-child(2) {
        margin-top: 8px;
        
        padding: 16px 0;

        box-shadow: 0 3px 6px #00000029;
        border: 1px solid #EAEAEA;
        border-radius: 4px;
        
        & > div:nth-child(1) {
          padding: 0;
          
          // left container
          & > div:nth-child(1) {
            border: none;
            padding: 0 8px;
            
            li {
              width: 224px;
              height: 36px;
              padding: 8px 16px;
              margin-bottom: 2px;
              font-weight: 500;
              
              color: #505050;
              
              &:hover {
                background: #0000000A;
                border-radius: 4px;
              }
            }
            
            ${props => props.selectedShortcutIndex !== undefined && `
              li:nth-child(${props.selectedShortcutIndex + 1}) {
                background: #2f008c0A;
                color: #2f008c;
              }
            `}
          }
          
          // right container 
          & > div:nth-child(2) {
            padding: 0 24px;
            
            // calendar
            & > div:nth-child(1), div:nth-child(3) {
              
              & > div:nth-child(1) {
                border: none;
              }
              
              & > div:nth-child(2) {
                font-size: 13px;
                
                & > .grid-cols-7:nth-child(1) {
                  border: none;
                  
                  color: #999999;
                }
                
                & > .grid-cols-7:nth-child(2) {
                  row-gap: 6px;
                  
                  & > button.text-blue-500 {
                    color: #2f008c;
                  }
                  
                  & > button.bg-blue-100 {
                    background-color: #EBE9FF;
                  }
                  
                  & > button {
                    width: 41px;
                    height: 40px;
                    position: relative;
                  }
                  

                  & > button.rounded-l-full {
                    background-color: #2f008c;
                    border-radius: 100% 0 0 100%;
                  }
                  
                  & > button.rounded-r-full {
                    background-color: #2f008c;
                    border-radius: 0 100% 100% 0;
                  }
                  
                  & > button.rounded-full {
                    background-color: #2f008c;
                    border-radius: 100%;
                  }
                  
                }

                // select month
                & > .grid-cols-2:nth-child(1) {
                  gap: 4px;
                  grid-template-columns: repeat(6, minmax(0, 1fr));
                  box-shadow: 0px 3px 6px #00000029;
                  border: 1px solid #EAEAEA;
                  border-radius: 8px;
                  
                  padding: 10px;
                  
                  & > button {
                    padding: 4px 6px;
                  }
                }
              }
            }
            
            // separator between two calendars
            & > div:nth-child(2) {
              visibility: hidden;
            }
          }
        }

        // footer
        & > div:nth-child(2) {
          border: none;
          padding: 16px 24px 0 0;
          font-size: 13px;
          
          & > div > {
            &:nth-child(1) {
              border: none;
              color: #999999;
            }
            
            &:nth-child(2) {
              width: 100px;
              background-color: #2f008c;
            }
          }
        }
      }
    }
  }
`

export default DateRangePicker;
